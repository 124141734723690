import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Edit } from "../../../assets/images/edit.svg"; // SVG 파일 import
import { ReactComponent as Delete } from "../../../assets/images/delete.svg";
import { stateToastStore } from "~/stores/state-toast.store";
import DeleteConfirmationDialog from "@components/atoms/memo/MemoDeleteDialog";
import { memoStore } from "~/stores/memo.store";
import { deleteMemo, getMemo } from "~/apis/knuh/postKnuhData"; // SVG 파일 import

// styled-components를 사용하여 SVG 스타일링
const IconContainer = styled.div`
  display: flex;
  width: 28px; // 원하는 스타일을 적용할 수 있습니다.
  height: 28px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    background-color: #3f3f3f; /* 마우스를 올렸을 때 배경색 변경 */
  }
`;

const ColoredEditIcon = styled(Edit)`
  path {
    fill: #a9a9ab; /* 원하는 색상 */
  }

  &:hover {
    path {
      fill: white; /* 마우스를 올렸을 때 배경색 변경 */
    }
  }
`;
const ColoredDeleteIcon = styled(Delete)`
  path {
    fill: #a9a9ab; /* 원하는 색상 */
  }

  &:hover {
    path {
      fill: white; /* 마우스를 올렸을 때 배경색 변경 */
    }
  }
`;

interface MemoIconProps {
  timestamp: string; // id의 타입을 정의
}

const MemoIcon = ({ timestamp }: MemoIconProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const handleConfirmDelete = () => {
    deleteMemo(timestamp);
    stateToastStore.showStateToast("작성한 메모가 삭제되었습니다.");

    setShowDialog(false);
  };

  const handleCancelDelete = () => {
    setShowDialog(false);
  };

  const handleDeleteClick = () => {
    setShowDialog(true);
  };

  const updateMemoIcon = () => {
    memoStore.setMemoUpdate(timestamp);
  };

  return (
    <>
      <IconContainer>
        <ColoredEditIcon onClick={updateMemoIcon} />
      </IconContainer>
      <IconContainer onClick={handleDeleteClick}>
        <ColoredDeleteIcon />
      </IconContainer>
      {showDialog && (
        <DeleteConfirmationDialog
          cancelButtonText="취소"
          checkButtonText="메모 삭제"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          onleave={handleCancelDelete}
        >
          메모를 삭제하시겠습니까?
        </DeleteConfirmationDialog>
      )}
    </>
  );
};
export default MemoIcon;
