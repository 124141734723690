import axios from "axios";
import { config } from "~/utils/agora.config";

export interface TokenRequest {
  classification: "RTT" | "CLOUD_RECORDING" | "WHITE_BOARD";
  user_id: string;
}

interface TokenData {
  uid: string;
  token: string;
  classification: string;
}

export interface TokenResponse {
  success: boolean;
  data: TokenData[];
}

export const fetchToken = async (
  roomKey: string,
  requestData: TokenRequest,
): Promise<TokenResponse> => {
  const url = `${config.soldocUrl}agora/${roomKey}/tokens/`;

  const configToken = {
    method: "post",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(requestData),
  };

  try {
    const response = await axios(configToken);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error response data:", error.response?.data);
      throw new Error(error.response?.data?.message || "Failed to fetch token");
    } else {
      console.error("Unexpected error:", error);
      throw new Error("An unexpected error occurred");
    }
  }
};

export interface rttStopRequest {
  user_id: string;
}

interface rttStopResponse {
  success: boolean;
}

export const rttStopApi = async (
  roomKey: string,
  requestData: rttStopRequest,
): Promise<rttStopResponse> => {
  const url = `${config.soldocUrl}agora/${roomKey}/rtt/`;

  const configRtt = {
    method: "post",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(requestData),
  };

  try {
    const response = await axios(configRtt);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error response data:", error.response?.data);
      throw new Error(error.response?.data?.message || "Failed to fetch token");
    } else {
      console.error("Unexpected error:", error);
      throw new Error("An unexpected error occurred");
    }
  }
};
