import axios from "axios";
import { config } from "~/utils/agora.config";

// RTT 정지
export const stopRTT = async (
  taskId: string,
  builderToken: string,
): Promise<void> => {
  const customerID = process.env.REACT_APP_AGORA_RTC_CUSTOMER_ID!;
  const customerSecret = process.env.REACT_APP_AGORA_RTC_CUSTOMER_SECRET!;
  const appID = process.env.REACT_APP_AGORA_RTC_APP_ID!;

  const url = `${config.agoraUrl}projects/${appID}/rtsc/speech-to-text/tasks/${taskId}?builderToken=${builderToken}`;

  const configRtt = {
    method: "delete",
    url: url,
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Basic ${btoa(`${customerID}:${customerSecret}`)}`,
      "Content-Type": "application/json",
    },
    data: "", // 빈 문자열을 데이터로 설정
    transformRequest: [
      (data: any, headers: any) => {
        headers["Content-Type"] = "application/json"; // Content-Type 헤더를 명시적으로 설정
        return data;
      },
    ],
  };

  try {
    await axios(configRtt);
    // console.log("RTT Task Stopped:", response.data);
    console.log("RTT Task Stopped");
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error response data:", error.response?.data);
      throw new Error(
        error.response?.data?.message || "Failed to stop RTT task",
      );
    } else {
      console.error("Unexpected error:", error);
      throw new Error("Unexpected error occurred");
    }
  }
};
