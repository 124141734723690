import { RtcTokenBuilder } from "agora-access-token";
import axios from "axios";
import { rttStore } from "~/stores/rtt.store";
import { config } from "~/utils/agora.config";

const generateToken = (uid: number, channelName: string): string => {
  // RTC 토큰 생성
  return RtcTokenBuilder.buildTokenWithUid(
    config.appId,
    config.appCertificate,
    channelName,
    uid,
    config.role,
    config.privilegeExpiredTs,
  );
};

// StartRTTResponse 인터페이스 정의
interface StartRTTResponse {
  taskId: string;
  createTs: number;
  status: string;
}

// RTT 시작
export const startRTT = async (
  channelName: string,
  builderToken: string,
  clientUid: any,
): Promise<StartRTTResponse> => {
  const customerID = process.env.REACT_APP_AGORA_RTC_CUSTOMER_ID!;
  const customerSecret = process.env.REACT_APP_AGORA_RTC_CUSTOMER_SECRET!;
  const appID = process.env.REACT_APP_AGORA_RTC_APP_ID!;

  const generateUID = () => Math.floor(Math.random() * 4294967295);
  let audioUID = generateUID();
  let textUID = generateUID();

  while (audioUID === textUID) {
    textUID = generateUID();
  }

  //   let audioUID = clientUid + 100_000_000;
  //   let textUID = clientUid + 200_000_000;

  const audioToken = generateToken(audioUID, channelName);
  const textToken = generateToken(textUID, channelName);

  const data = {
    languages: ["ko-KR"],
    maxIdleTime: 60,
    rtcConfig: {
      channelName: channelName,
      subBotUid: audioUID.toString(),
      subBotToken: audioToken,
      pubBotUid: textUID.toString(),
      pubBotToken: textToken,
      subscribeAudioUids: [clientUid.toString()],
      // cryptionMode: "AES128XTS", // Optional, if need cryption for audio and caption text
      // secret: process.env.REACT_APP_CRYPTION_SECRET!, // Optional, if need decryption for audio and caption text
      // salt: process.env.REACT_APP_CRYPTION_SALT!, // Optional, if need decryption for audio and caption text
    },

    // 자막파일 s3 전송
    // captionConfig: {
    //   storage: {
    //     accessKey: process.env.REACT_APP_S3_ACCESS_KEY!,
    //     secretKey: process.env.REACT_APP_S3_SECRET_KEY!,
    //     bucket: process.env.REACT_APP_S3_BUCKET!,
    //     vendor: 1,
    //     region: 11,
    //     fileNamePrefix: [appStore.roomName, appStore.uid?.toString(), "rtt"],
    //   },
    // },

    // 번역 기능
    // translateConfig: {
    //   forceTranslateInterval: 5,
    //   languages: [
    //     {
    //       source: "en-US",
    //       target: ["ko-KR"],
    //     },
    //   ],
    // },
  };

  const url = `${config.agoraUrl}projects/${appID}/rtsc/speech-to-text/tasks?builderToken=${builderToken}`;

  const configRtt = {
    method: "post",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(`${customerID}:${customerSecret}`)}`,
    },
    data: JSON.stringify(data),
  };

  try {
    const response = await axios(configRtt);
    console.log("RTT Task Started");
    return response.data as StartRTTResponse;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // 이미 자막이 전사중이라는 에러가 발생하면 전사중인 작업의 taskId, tokenName을 저장
      console.error("Axios error response data:", error.response?.data);
      await rttStore.setTaskId(error.response?.data.taskId);
      await rttStore.setBuilderToken(error.response?.data.tokenName);
      // 자막 컴포넌트를 활성화
      rttStore.setRttState(true);
      // 자막 정보를 업데이트
      await rttStore.updateRttToken();
      throw new Error(
        error.response?.data?.message || "Failed to start RTT task",
      );
    } else {
      console.error("Unexpected error:", error);
      throw new Error("Unexpected error occurred");
    }
  }
};
