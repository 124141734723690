import { ReactComponent as ArrowDropDownIcon } from "~/assets/images/arrow-drop-down.svg";
import styled from "styled-components";

const ArrowContainer = styled.div`
  display: inline-block;
  transform: rotate(0deg);
`;

const ArrowContainerOpen = styled.div`
  display: inline-block;
  transform: rotate(180deg);
`;

interface ArrowDropDownProps {
  isOpen: boolean;
}

export const ArrowDropDown = ({ isOpen }: ArrowDropDownProps) => {
  return (
    <>
      {isOpen ? (
        <ArrowContainerOpen>
          <ArrowDropDownIcon />
        </ArrowContainerOpen>
      ) : (
        <ArrowContainer>
          <ArrowDropDownIcon />
        </ArrowContainer>
      )}
    </>
  );
};
