import { RtcRole } from "agora-access-token";

export interface ConfigType {
  appId: string;
  appCertificate: string;
  uid: number; // 유저 ID (0이면 UID가 자동으로 생성됨)
  role: number; // Agora RTC 역할 (예: 호스트, 청중)
  expirationTimeInSeconds: number; // 토큰의 유효 시간 (초 단위)
  currentTimestamp: number; // 현재 타임스탬프 (초 단위)
  privilegeExpiredTs: number; // 권한 만료 타임스탬프 (초 단위)
  soldocUrl: string;
  agoraUrl: string;
}

// Agora config
export const config: ConfigType = {
  appId: process.env.REACT_APP_AGORA_RTC_APP_ID!,
  appCertificate: process.env.REACT_APP_AGORA_RTC_APP_CERTIFICATE!,
  uid: 0, // UID가 0이면 Agora에서 자동으로 UID를 생성합니다.
  role: RtcRole.PUBLISHER, // 사용자의 역할을 퍼블리셔(호스트)로 설정합니다.
  expirationTimeInSeconds: 3600,
  currentTimestamp: Math.floor(Date.now() / 1000),
  soldocUrl: process.env.REACT_APP_SOLDOC_SERVER_URL!,
  agoraUrl: process.env.REACT_APP_AGORA_SERVER_URL!,
  get privilegeExpiredTs(): number {
    return this.currentTimestamp + this.expirationTimeInSeconds;
  },
};
