import logo from "~/assets/images/logo.png";
import { ModalCam } from "../../molecules/modal-elements/modal-cam/ModalCam";
import { ModalOption } from "../../molecules/modal-elements/modal-option/ModalOption";
import { Logo, ModalContainer } from "./ModalStyles";
import React from "react";

/**
 * @returns 모달 컴포넌트
 */
export const Modal = () => {
  return (
    <>
      {/* 솔닥 로고 */}
      <Logo src={logo} alt="Logo" />
      <ModalContainer>
        {/* 카메라, 마이크 미리보기 컴포넌트 */}
        <ModalCam />
        {/* 카메라, 마이크 선택 컴포넌트 */}
        <ModalOption />
      </ModalContainer>
    </>
  );
};
