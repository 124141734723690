import { useEffect, useState, useCallback } from "react";
import type { ILocalAudioTrack, IRemoteAudioTrack } from "agora-rtc-react";
import { useVolumeLevel } from "agora-rtc-react";
import {
  MicBars,
  MicBar,
  MicIconAnimationContainer,
} from "./MicIconAnimationStyles";

export interface MicIconAnimationProps {
  audioTrack?: ILocalAudioTrack | IRemoteAudioTrack;
  micOn?: boolean;
  noise?: number;
  className?: string;
}

/** MicIconAnimation 컴포넌트는 마이크 아이콘의 움직임을 시각화합니다. */
export const MicIconAnimation = ({
  noise = 0.5,
  audioTrack,
  micOn = false,
  className = "",
  ...props
}: MicIconAnimationProps) => {
  // audioTrack에서 볼륨 레벨을 가져옵니다.
  const volumeLevel = useVolumeLevel(audioTrack);
  // 마이크 아이콘의 막대 높이를 상태로 관리합니다.
  const [heights, setHeights] = useState([3, 3, 3, 3]);

  // calculateBarHeight 함수를 useCallback으로 메모이제이션합니다.
  const calculateBarHeight = useCallback(
    (index: number) => {
      const minBarHeight = 3; // 최소 막대 높이
      const maxBarHeights = [16, 8, 24, 12]; // 각 막대의 최대 높이
      const maxBarHeight = maxBarHeights[index]; // 현재 막대의 최대 높이

      if (volumeLevel <= 0.5) {
        return minBarHeight; // 볼륨이 낮을 때 최소 높이로 설정
      }

      // 볼륨 값을 스케일링하여 낮은 볼륨에서도 최대 높이에 도달할 수 있도록 조정
      const scaledVolume = Math.min(1, volumeLevel * 1.2);

      // 노이즈 추가
      const noiseLevel = noise * maxBarHeight * (Math.random() - 0.5);
      const adjustedHeight =
        minBarHeight +
        scaledVolume * (maxBarHeight - minBarHeight) +
        noiseLevel;

      // 최소 높이와 최대 높이 사이의 값을 반환
      return Math.max(minBarHeight, Math.min(adjustedHeight, maxBarHeight));
    },
    [volumeLevel, noise]
  );

  // micOn 상태가 변경될 때마다 막대 높이를 업데이트합니다.
  useEffect(() => {
    if (!micOn) {
      setHeights([3, 3, 3, 3]); // 마이크가 꺼져있으면 막대 높이를 최소로 설정
      return;
    }

    // 주기적으로 막대 높이를 업데이트합니다.
    const interval = setInterval(() => {
      setHeights((prevHeights) =>
        prevHeights.map((_, index) => calculateBarHeight(index))
      );
    }, 200);

    // 컴포넌트가 언마운트될 때 인터벌을 정리합니다.
    return () => clearInterval(interval);
  }, [volumeLevel, micOn, calculateBarHeight]);

  return (
    <MicIconAnimationContainer {...props} className={`${className}`}>
      {micOn && (
        <MicBars>
          {heights.map((height, index) => (
            <MicBar key={index} height={height} />
          ))}
        </MicBars>
      )}
    </MicIconAnimationContainer>
  );
};
