import { ControlsContainer } from "./ControlsStyles";
import { ControlsLogo } from "~/components/molecules/controls-elements/controls-logo/ControlsLogo";
import { ControlsFeature } from "~/components/molecules/controls-elements/controls-feature/ControlsFeature";
import { ControlsRatio } from "~/components/molecules/controls-elements/controls-ratio/ControlsRatio";
import { ExitPage } from "@components/atoms/exitButton/ExitButton";

/**
 * 컨트롤러 컴포넌트
 */
export const Controls = () => {
  return (
    <ControlsContainer>
      {/* 로고 컴포넌트 */}
      <ControlsLogo />
      {/* 기능 제어 컴포넌트 */}
      <ControlsFeature />
      {/* 화면비율 제어 컴포넌트 */}
      <ControlsRatio />
      {/* 나가기 버튼 컴포넌트 */}
      <ExitPage />
    </ControlsContainer>
  );
};
