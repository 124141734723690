import React, { useState } from "react";
import { ReactComponent as MemoIcon } from "~/assets/images/edit_document.svg";
import { Tooltip } from "../../tooltip/Tooltip";
import { observer } from "mobx-react-lite";
import { memoStore } from "~/stores/memo.store";
import { ButtonContainer, Button } from "./MemoButtonStyles";
import { getMemo } from "~/apis/knuh/postKnuhData";

/** MemoButton 컴포넌트는 메모 기능을 제어하는 버튼을 나타냅니다. */
export const MemoButton = observer(() => {
  // 메모 버튼이 선택되었는지 여부를 상태로 관리합니다.
  const [selected, setSelected] = useState(false);
  // 툴팁의 가시성을 상태로 관리합니다.
  const [tooltipVisible, setTooltipVisible] = useState(false);

  // 툴팁을 보이게 합니다.
  const showTooltip = () => setTooltipVisible(true);
  // 툴팁을 숨깁니다.
  const hideTooltip = () => setTooltipVisible(false);

  // 메모 토글 버튼을 클릭했을 때 호출되는 함수입니다.
  const handleMemoToggle = () => {
    setSelected(!selected);
    getMemo();
    // getMemo();
    memoStore.toggleMemo();
  };

  return (
    <ButtonContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {/* 툴팁을 렌더링합니다. */}
      <Tooltip message="메모" visible={tooltipVisible} />
      {/* 메모 토글 버튼을 렌더링합니다. */}
      <Button selected={selected} onClick={handleMemoToggle}>
        <MemoIcon />
      </Button>
    </ButtonContainer>
  );
});
