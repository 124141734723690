import { styled } from "styled-components";

export const ControlsRatioContainer = styled.div`
  padding: 10px 0 10px 0px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  flex: 1;
  user-select: none;
`;

export const RatioButton = styled.button<{ selected: boolean }>`
  width: 78px;
  height: 40px;
  background-color: ${({ selected }) => (selected ? "#27a3fc" : "#141414")};
  border-radius: 8px;
  border: 1px solid var(--Foundation-gray-gray-600, #737375);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ selected }) =>
    selected ? "#fff" : "var(--Foundation-Black-black-5, #d9d9d9)"};
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;
  font-family: "Pretendard", sans-serif;

  &:hover {
    color: #fff;
    border: 1px solid #27a3fc;
  }
`;
