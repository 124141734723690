import { styled } from "styled-components";

export const ControlsLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  user-select: none;
`;

export const Logo = styled.img`
  width: 100px;
  height: 27px;
`;
