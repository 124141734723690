import axios from "axios";
import { config } from "~/utils/agora.config";

// AcquireResponse 인터페이스 정의
interface AcquireResponse {
  tokenName: string;
  createTs: number;
  instanceId: string;
}

// RTT 토큰 발급
export const acquireBuilderToken = async (
  instanceId: string,
): Promise<AcquireResponse> => {
  const customerID = process.env.REACT_APP_AGORA_RTC_CUSTOMER_ID!;
  const customerSecret = process.env.REACT_APP_AGORA_RTC_CUSTOMER_SECRET!;
  const appID = process.env.REACT_APP_AGORA_RTC_APP_ID!;

  const url = `${config.agoraUrl}projects/${appID}/rtsc/speech-to-text/builderTokens`;
  const data = JSON.stringify({ instanceId });

  const configRtt = {
    method: "post",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(`${customerID}:${customerSecret}`)}`,
    },
    data: data,
  };

  try {
    const response = await axios(configRtt);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error response data:", error.response?.data);
      throw new Error(
        error.response?.data?.message || "Failed to acquire builder token",
      );
    } else {
      console.error("Unexpected error:", error);
      throw new Error("Unexpected error occurred");
    }
  }
};
