import type {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ILocalAudioTrack,
  ILocalTrack,
  ILocalVideoTrack,
  IRemoteAudioTrack,
  IRemoteTrack,
  IRemoteVideoTrack,
  UID,
} from "agora-rtc-react";
import AgoraRTC from "agora-rtc-react";
import { makeAutoObservable, runInAction } from "mobx";
import { appStore } from "./app.store";
import { RtcTokenBuilder } from "agora-access-token";
import { config } from "~/utils/agora.config";
import { stateToastStore } from "./state-toast.store";
import { RTMMessage, rtmStore } from "./rtm.store";

// 화면 공유를 위한 UID 상수 정의
export const ShareScreenUID: UID = 10;

/**
 * 화면 공유를 위한 클래스 정의
 */
export class ShareScreen {
  readonly uid: UID = ShareScreenUID; // 화면 공유를 위한 고유 사용자 ID
  readonly client: IAgoraRTCClient = AgoraRTC.createClient({
    mode: "rtc",
    codec: "vp8",
  }); // Agora RTC 클라이언트 생성
  ready: boolean = false; // 화면 공유 준비 상태
  enabled: boolean = false; // 화면 공유 활성화 상태
  disableShareScreenButton: boolean = false; // 화면 공유 버튼 비활성화 상태
  localAudioTrack: ILocalAudioTrack | null = null; // 로컬 오디오 트랙
  localVideoTrack: ILocalVideoTrack | null = null; // 로컬 비디오 트랙
  remoteAudioTrack: IRemoteAudioTrack | null = null; // 원격 오디오 트랙
  remoteVideoTrack: IRemoteVideoTrack | null = null; // 원격 비디오 트랙

  private eventHandlers: { event: string; handler: Function }[] = []; // 이벤트 핸들러 목록
  private _pTogglingShareScreen?: Promise<void>; // 화면 공유 상태 토글링 중인 Promise

  /**
   * 클래스 생성자
   */
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 화면 공유가 실행 중인지 확인하는 getter
   * @returns {boolean} 화면 공유 실행 상태
   */
  get isRunning(): boolean {
    return this.enabled || this.remoteVideoTrack !== null;
  }

  /**
   * 화면공유 활성화 상태를 설정하는 함수
   * @param state - 설정할 활성화 상태 (true: 활성화, false: 비활성화)
   */
  setEnabled = (state: boolean) => {
    this.enabled = state;
  };

  /**
   * 화면공유 버튼 비활성화 상태를 설정하는 함수
   * @param state - 설정할 버튼 비활성화 상태 (true: 비활성화, false: 활성화)
   */
  setDisableShareScreenButton = (state: boolean) => {
    this.disableShareScreenButton = state;
  };

  /**
   * agora-access-token을 이용해 토큰을 생성하는 함수
   * @returns {string} 생성된 토큰
   */
  private generateToken(): string {
    if (!appStore.roomName) throw new Error("roomId가 설정되지 않았습니다");
    return RtcTokenBuilder.buildTokenWithUid(
      config.appId,
      config.appCertificate,
      appStore.roomName,
      config.uid,
      config.role,
      config.privilegeExpiredTs
    );
  }

  /**
   * 메인 RTC 클라이언트를 업데이트하고 사용자 publish/unpublish 이벤트 핸들러를 설정하는 함수
   * @param {IAgoraRTCClient | null} client - Agora RTC 클라이언트
   */
  updateMainClient(client: IAgoraRTCClient | null): void {
    if (client && client.uid) {
      // 사용자가 미디어를 게시했을 때 호출되는 함수
      const handleUserPublished = async (
        user: IAgoraRTCRemoteUser,
        mediaType: "audio" | "video" | "datachannel"
      ) => {
        // 화면 공유의 UID와 다르거나, 이미 화면 공유가 활성화된 경우, datachannel인 경우 처리하지 않음
        if (
          user.uid !== this.uid ||
          this.enabled ||
          mediaType === "datachannel"
        )
          return;
        // 원격 사용자의 트랙을 구독
        const track = (await client.subscribe(user, mediaType)) as IRemoteTrack;
        runInAction(() => {
          this.setRemoteTrack(track, mediaType); // 원격 트랙 설정
        });
      };

      // 사용자가 미디어 게시를 중단했을 때 호출되는 함수
      const handleUserUnpublished = (
        user: IAgoraRTCRemoteUser,
        mediaType: "audio" | "video" | "datachannel"
      ) => {
        if (user.uid !== this.uid || mediaType === "datachannel") return;
        runInAction(() => {
          this.setRemoteTrack(null, mediaType); // 원격 트랙 해제
        });
      };

      // 이벤트 핸들러 등록
      client.on("user-published", handleUserPublished);
      client.on("user-unpublished", handleUserUnpublished);

      // 이벤트 핸들러 목록 저장
      this.eventHandlers = [
        { event: "user-published", handler: handleUserPublished },
        { event: "user-unpublished", handler: handleUserUnpublished },
      ];
    }
  }

  /**
   * 원격 트랙을 설정하는 함수
   * @param {IRemoteTrack | null} track - 설정할 원격 트랙
   * @param {"audio" | "video"} mediaType - 트랙의 미디어 타입
   */
  setRemoteTrack(
    track: IRemoteTrack | null,
    mediaType: "audio" | "video"
  ): void {
    if (mediaType === "audio") {
      this.remoteAudioTrack = track as IRemoteAudioTrack;
    } else if (mediaType === "video") {
      this.remoteVideoTrack = track as IRemoteVideoTrack;
    }
  }

  /**
   * 모든 이벤트 핸들러를 제거하고 트랙을 정리하는 함수
   * @returns {Promise<void>}
   */
  async dispose(): Promise<void> {
    // 등록된 모든 이벤트 핸들러 제거
    this.eventHandlers.forEach(({ event, handler }) => {
      this.client.off(event, handler);
    });
    this.eventHandlers = [];

    // 원격 트랙 정리
    runInAction(() => {
      if (this.remoteVideoTrack) {
        this.remoteVideoTrack.stop();
        this.remoteVideoTrack = null;
      }
      if (this.remoteAudioTrack) {
        this.remoteAudioTrack.stop();
        this.remoteAudioTrack = null;
      }
    });
    await this.disable(); // 화면 공유 비활성화
  }

  /**
   * 화면 공유를 활성화하는 함수
   * @returns {Promise<void>}
   */
  async enable(): Promise<void> {
    if (this.remoteVideoTrack) {
      console.log("상대방이 화면 공유중입니다.");
      stateToastStore.showStateToast("상대방이 화면 공유중입니다.");
      return;
    }

    if (this._pTogglingShareScreen) {
      await this._pTogglingShareScreen;
    }

    if (!this.localVideoTrack) {
      let resolve!: () => void;
      this._pTogglingShareScreen = new Promise<void>((resolve_) => {
        resolve = resolve_;
      });

      try {
        await this.createLocalTracks();
      } catch {
        resolve();
        this._pTogglingShareScreen = undefined;
        return;
      }
      const token = this.generateToken();

      await this.client.join(
        config.appId,
        appStore.roomName,
        token,
        ShareScreenUID
      );
      await this.client.publish(this._getLocalTracks());

      resolve();
      this._pTogglingShareScreen = undefined;
    }

    this.setEnabled(true);
    this.sendShareScreenMessage("enable");
    stateToastStore.showStateToast("화면공유를 활성화합니다.");
  }

  /**
   * 화면 공유를 비활성화하는 함수
   * @returns {Promise<void>}
   */
  async disable(): Promise<void> {
    this.setEnabled(false);

    if (this._pTogglingShareScreen) {
      await this._pTogglingShareScreen;
    }

    if (this.localVideoTrack) {
      let resolve!: () => void;
      this._pTogglingShareScreen = new Promise<void>((resolve_) => {
        resolve = resolve_;
      });

      await this.client.unpublish(this._getLocalTracks());
      runInAction(() => {
        this.localVideoTrack?.close();
        this.localVideoTrack = null;
        if (this.localAudioTrack) {
          this.localAudioTrack.close();
          this.localAudioTrack = null;
        }
      });
      await this.client.leave();

      resolve();
      this._pTogglingShareScreen = undefined;
    }
  }

  /**
   * 로컬 트랙을 생성하는 함수
   * @returns {Promise<void>}
   */
  private async createLocalTracks(): Promise<void> {
    const ret = await AgoraRTC.createScreenVideoTrack(
      { encoderConfig: "1080p_2" },
      "auto"
    );
    runInAction(() => {
      if (Array.isArray(ret)) {
        [this.localVideoTrack, this.localAudioTrack] = ret;
      } else {
        this.localVideoTrack = ret;
        this.localAudioTrack = null;
      }
    });
    this.localVideoTrack?.once("track-ended", () => this.disable());
  }

  /**
   * 로컬 트랙을 반환하는 함수
   * @returns {ILocalTrack[]} 로컬 트랙 배열
   */
  private _getLocalTracks(): ILocalTrack[] {
    return [this.localAudioTrack, this.localVideoTrack].filter(
      Boolean
    ) as ILocalTrack[];
  }

  /**
   * 화이트보드 RTM 메세지 전송
   * @param action - "ready", "start", "leave" 중 하나의 액션 타입
   */
  sendShareScreenMessage = async (
    action: "ready" | "enable" | "disable",
    data?: true | false
  ) => {
    const message = {
      type: "share-screen",
      action: action,
      data: data,
    } as RTMMessage;

    await rtmStore.sendMessage(message);
  };

  /**
   * RTM 메시지에 따라 화면 공유 상태를 처리하는 함수
   * @param {RTMMessage} message - 처리할 RTM 메시지
   * @returns {Promise<void>}
   */
  handleShareScreenRTMMessage = async (message: RTMMessage): Promise<void> => {
    if (message.action === "enable") {
      stateToastStore.showStateToast("화면공유를 활성화합니다.");
    } else if (message.action === "disable") {
      stateToastStore.showStateToast("화면공유를 비활성화합니다.");
    } else if (message.action === "ready") {
      if (message.data === true) {
        this.ready = true;
        stateToastStore.showStateToast("상대방이 화면 공유 준비 중입니다.");
      } else if (message.data === false) {
        this.ready = false;
      }
    }
  };
}
