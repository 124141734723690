import { useState } from "react";
import { ReactComponent as RttIcon } from "~/assets/images/closed_caption.svg";
import { Tooltip } from "../../tooltip/Tooltip";
import { observer } from "mobx-react-lite";
import { rttStore } from "~/stores/rtt.store";
import { stateToastStore } from "~/stores/state-toast.store";
import { ButtonContainer, Button } from "./RttButtonStyles";
import { appStore } from "~/stores/app.store";

/** RttButton */
export const RttButton = observer(() => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  // 자막 토글 버튼을 클릭했을 때 호출되는 함수입니다.
  const handleRttToggle = async () => {
    // 자막 버튼을 비활성화 합니다.
    rttStore.setDisableRttButton(true);
    try {
      if (rttStore.rttComponentState === false) {
        if (rttStore.rttState === true) {
          // rtt 기능 상태가 true이면 rtt 컴포넌트를 렌더링합니다.
          rttStore.setRttComponentState(true);
          stateToastStore.showStateToast("자막을 활성화 합니다.");
        } else if (rttStore.rttState === false) {
          stateToastStore.showStateToast("자막 준비중...");
          // rtt 기능 상태가 false이면 상대방에게 rtt 기능을 시작하라는 메세지를 전송합니다.
          rttStore.sendRttMessage("start");
          // 자막 토큰을 받아 자막 기능을 활성화 합니다.
          await rttStore.handleAcquireToken();
          await rttStore.handleStartRTT();
          // rtt taskId와 builderToken을 업데이트합니다.
          await rttStore.updateRttToken();
          // rtt 컴포넌트를 렌더링합니다.
          rttStore.setRttComponentState(true);
          stateToastStore.showStateToast("자막을 활성화 합니다.");
        }
      } else {
        if (appStore.users.remoteUsers.length === 0) {
          stateToastStore.showStateToast("자막 비활성화 중...");
          await rttStore.handleStopRTT();
          stateToastStore.showStateToast("자막을 비활성화 합니다.");
        }
        // rtt 컴포넌트를 언마운트 할 때 stop 메세지를 전송합니다.
        rttStore.sendRttMessage("stop");
        rttStore.setRttComponentState(false);
        stateToastStore.showStateToast("자막을 비활성화 합니다.");
      }
    } catch (error) {
      // 자막 버튼을 2초 뒤에 활성화 합니다.
      setTimeout(() => {
        rttStore.setDisableRttButton(false);
      }, 2000);
    }
    // 자막 버튼을 2초 뒤에 활성화 합니다.
    setTimeout(() => {
      rttStore.setDisableRttButton(false);
    }, 2000);
  };

  return (
    <ButtonContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {/* 툴팁을 렌더링합니다. */}
      <Tooltip message="자막" visible={tooltipVisible} />
      {/* 자막 토글 버튼을 렌더링합니다. */}
      <Button
        selected={rttStore.rttComponentState}
        onClick={handleRttToggle}
        disabled={rttStore.disableRttButton}
      >
        <RttIcon />
      </Button>
    </ButtonContainer>
  );
});
