import { makeAutoObservable } from "mobx";
import { acquire } from "~/apis/recording/Acquire";
import { RTMMessage, rtmStore } from "./rtm.store";
import { stateToastStore } from "./state-toast.store";
import { cloudRecordingStart } from "~/apis/room/SoldocServer";
import { getCurrentService } from "~/apis/agora/getCurrentService";

/**
 * 녹화 상태를 관리하는 스토어 클래스
 */
class RecordingStore {
  recordingState: boolean = false; // 녹화 상태 (활성화 여부)
  disabledRecordButton: boolean = false; // 녹화 버튼 비활성화 상태
  resourceId: string = ""; // 리소스 ID
  sid: string = ""; // 세션 ID

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 세션 ID 설정
   * @param {string} sid - 세션 ID
   */
  setSid = (sid: string): void => {
    this.sid = sid;
  };

  /**
   * 리소스 ID 설정
   * @param {string} resourceId - 리소스 ID
   */
  setResourceId = (resourceId: string): void => {
    this.resourceId = resourceId;
  };

  /**
   * 녹화 상태 설정
   * @param {boolean} state - 녹화 상태
   */
  setRecordingState = (state: boolean): void => {
    this.recordingState = state;
  };

  /**
   * 녹화 버튼 비활성화 상태 설정
   * @param {boolean} state - 녹화 버튼 비활성화 상태
   */
  setDisabledRecordingButton = (state: boolean): void => {
    this.disabledRecordButton = state;
  };

  /**
   * 녹화 시작
   * @returns {Promise<void>}
   */
  recordingStart = async (): Promise<boolean> => {
    // 리소스 ID를 획득
    const resourceId = await acquire();
    this.setResourceId(resourceId);

    // 세션 ID를 획득
    // const sid = await recordingPost();
    // this.setSid(sid);

    const response = cloudRecordingStart();
    return response;
  };

  /**
   * 녹화 RTM 메세지 전송
   * @param action - "start", "stop" 중 하나의 액션 타입
   */
  sendRecordingMessage = async (action: "start" | "stop") => {
    const message = {
      type: "recording",
      action: action,
      data: {},
    } as RTMMessage;

    await rtmStore.sendMessage(message);
  };

  /**
   * RTM 메시지에 따라 녹화 상태 처리
   * @param {RTMMessage} message - 처리할 RTM 메시지
   * @returns {Promise<void>}
   */
  handleRecordingRTMMessage = async (message: RTMMessage): Promise<void> => {
    if (message.action === "start") {
      // 녹화를 시작하라는 메시지를 받으면,
      // 녹화 상태를 true로 설정하고,
      // 녹화 버튼을 비활성화 상태로 설정합니다.
      stateToastStore.showStateToast("녹화를 활성화합니다.");
      this.setRecordingState(true);
      this.setDisabledRecordingButton(true);
    } else if (message.action === "stop") {
      // 녹화를 중지하라는 메시지를 받으면,
      // 녹화 상태를 false로 설정하고,
      // 녹화 버튼을 활성화 상태로 설정합니다.
      stateToastStore.showStateToast(
        "녹화된 영상은 경북대 병원 서버에 저장됩니다."
      );
      this.setRecordingState(false);
      this.setDisabledRecordingButton(false);
    }
  };

  /**
   * 녹화상태를 체크하는 메소드
   * @returns {Promise<void>}
   */
  checkRecordingState = async (): Promise<void> => {
    try {
      const response = await getCurrentService();
      if (response && response.data.service_list.includes("CLOUD_RECORDING")) {
        this.setRecordingState(true);
        this.setDisabledRecordingButton(true);
      }
    } catch (error) {
      console.error("Failed to check recording state:", error);
    }
  };
}

export const recordingStore = new RecordingStore();
