import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { memoStore } from "~/stores/memo.store";
import { TextAreaScroll } from "~/components/molecules/memo-elements/MemoScroll";
import { patchMemo } from "~/apis/knuh/postKnuhData";

interface MemoEditProps {
  children: React.ReactNode;
  timestamp: string;
}

const EditTextarea = styled(TextAreaScroll)`
  all: unset; /* 기본 스타일 제거 */
  width: 278px;
  height: 72px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: white;
  display: -webkit-box;
  overflow: auto; /* 스크롤 추가 */
  cursor: text; /* 커서를 텍스트 커서로 변경 */
`;

const EditContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const EditedButton = styled.button`
  all: unset; /* 기본 스타일 제거 */
  width: 52px;
  height: 32px;
  border-radius: 8px;
  background-color: #008def;
  text-align: center;
  border: 1px #27a3fc; /* 외곽선 설정 */
  font-size: 16px;
  color: white;
  cursor: pointer;
`;

const CancelButton = styled.button`
  all: unset; /* 기본 스타일 제거 */
  width: 50px;
  height: 30px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  color: white;
  background-color: transparent; /* 배경색을 투명으로 설정 */
  border: 1px solid #737375; /* 외곽선 설정 */
  cursor: pointer;
`;

// 사용 예시
export const MemoEdit: React.FC<MemoEditProps> = ({ children, timestamp }) => {
  const [editContent, setEditContent] = useState(""); // 텍스트 상자의 내용을 상태로 관리
  const textareaRef = useRef<HTMLTextAreaElement>(null); // useRef 사용

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditContent(e.target.value); // 텍스트 상자의 내용 업데이트
  };

  useEffect(() => {
    if (typeof children === "string") {
      setEditContent(children); // 초기값을 children으로 설정
    }
  }, [children]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus(); // textarea에 포커스 설정
    }
  }, []);

  const handleEditClick = () => {
    // updateMemo(id, editContent);
    patchMemo(timestamp, editContent, "MEMO");
    memoStore.setMemoUpdate("");
  };
  const handleCancleClick = () => {
    memoStore.setMemoUpdate("");
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // 엔터 키 기본 동작 취소
      if (editContent) {
        handleEditClick();
      }
    }
  };
  return (
    <>
      <EditTextarea
        ref={textareaRef} // useRef 연결
        value={editContent}
        onChange={handleTextChange}
        onKeyPress={handleKeyPress}
      ></EditTextarea>
      <EditContainer>
        <CancelButton onClick={handleCancleClick}>취소</CancelButton>
        <EditedButton disabled={!editContent} onClick={handleEditClick}>
          저장
        </EditedButton>
      </EditContainer>
    </>
  );
};

export default MemoEdit;
