import React, { useState } from "react";
import { ReactComponent as ScreenShareIcon } from "~/assets/images/present_to_all.svg";
import { Tooltip } from "../../tooltip/Tooltip";
import { appStore } from "~/stores/app.store";
import { observer } from "mobx-react-lite";
import { stateToastStore } from "~/stores/state-toast.store";
import { ButtonContainer, Button } from "./ScreenShareButtonStyles";

export const ScreenShareButton = observer(() => {
  const { shareScreen } = appStore;
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  // 화면 공유 버튼을 클릭했을 때 호출되는 함수입니다.
  const handleScreenShareToggle = async () => {
    try {
      // 화면공유 준비 상태가 true일때
      if (shareScreen.ready === true) {
        stateToastStore.showStateToast("상대방이 화면 공유 준비 중입니다.");
        return;
      }
      // 화면공유 버튼을 비활성화
      shareScreen.setDisableShareScreenButton(true);

      // 화면공유 상태가 true일때
      if (shareScreen.enabled) {
        // 화면공유 비활성화
        await shareScreen.disable();
        stateToastStore.showStateToast("화면공유를 비활성화합니다.");
        shareScreen.sendShareScreenMessage("disable");
      }
      // 화면공유 상태가 false일때
      else {
        // 상대방이 화면 공유중이라면 화면공유 버튼조작 불가능
        if (shareScreen.remoteVideoTrack) {
          stateToastStore.showStateToast("상대방이 화면 공유중입니다.");
          setTimeout(() => {
            shareScreen.setDisableShareScreenButton(false);
          }, 2000);
          return;
        }
        // 화면공유 준비중 메세지 전송
        shareScreen.sendShareScreenMessage("ready", true);
        stateToastStore.showStateToast("화면공유 준비 중입니다.");
        // 화면공유 활성화
        await shareScreen.enable();
        // 화면공유 준비완료 메세지 전송
        shareScreen.sendShareScreenMessage("ready", false);
      }
      // 2초뒤에 화면공유 버튼을 활성화합니다.
      setTimeout(() => {
        shareScreen.setDisableShareScreenButton(false);
      }, 2000);
    } catch (error) {
      // 2초뒤에 화면공유 버튼을 활성화합니다.
      setTimeout(() => {
        shareScreen.setDisableShareScreenButton(false);
      }, 2000);
    }
  };

  return (
    <ButtonContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {/* 툴팁 컴포넌트 */}
      <Tooltip message="화면공유" visible={tooltipVisible} />
      {/* 화면 공유 버튼 컴포넌트 */}
      <Button
        selected={shareScreen.enabled}
        onClick={handleScreenShareToggle}
        disabled={shareScreen.disableShareScreenButton}
      >
        <ScreenShareIcon />
      </Button>
    </ButtonContainer>
  );
});
