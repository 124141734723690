import React, { useRef } from "react";
import styled, { keyframes } from "styled-components";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as TooltipIcon } from "~/assets/images/Triangle.svg";

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(5px);
    }
`;

const TooltipContainer = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
`;

const TooltipTextContainer = styled.div`
  min-width: 30px;
  max-width: 200px;
  padding: 6px 16px 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #454545;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  width: auto;
  white-space: nowrap;
`;

const TooltipIconStyled = styled(TooltipIcon)`
  width: 10px;
  height: 5px;
`;

const TooltipText = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

const FadeInOutTransition = styled.div`
  &.fade-enter {
    animation: ${fadeIn} 0.3s forwards;
  }

  &.fade-exit {
    animation: ${fadeOut} 0.3s forwards;
  }
`;

interface TooltipProps {
  message: string;
  visible: boolean;
}

export const Tooltip = ({ message, visible }: TooltipProps) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames="fade"
      unmountOnExit
      nodeRef={nodeRef}
    >
      <FadeInOutTransition ref={nodeRef}>
        <TooltipContainer>
          <TooltipTextContainer>
            <TooltipText>{message}</TooltipText>
          </TooltipTextContainer>
          <TooltipIconStyled />
        </TooltipContainer>
      </FadeInOutTransition>
    </CSSTransition>
  );
};
