import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Room } from "./pages/Room";
import { ScreenRecording } from "./pages/ScreenRecording";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { CallEndScreen } from "./pages/CallEndScreen";

/**
 * 파라미터에 따라 녹화봇 시점, 사용자 시점으로 구분합니다
 * @returns Room 또는 ScreenRecording 컴포넌트를 조건에 따라 반환
 */
const RoomRoute = () => {
  // 새로고침 막기 변수
  const preventClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
  };

  // 브라우저에 렌더링 시 한 번만 실행하는 코드
  useEffect(() => {
    (() => {
      window.addEventListener("beforeunload", preventClose);
    })();

    return () => {
      window.removeEventListener("beforeunload", preventClose);
    };
  }, []);

  const { roomName, user_id } = useParams<{
    roomName: string;
    user_id: string;
  }>();
  const BOT_SECRET_KEY = process.env.REACT_APP_BOT_SECRET_KEY;
  const renderComponent = () => {
    if (!roomName || !user_id) {
      return null;
    }
    if (user_id === BOT_SECRET_KEY) {
      return <ScreenRecording />; // 녹화봇이 보는 시점을 렌더링하는 컴포넌트
    }
    return <Room />; // 이용자가 화상통화를 사용하는 컴포넌트
  };

  return renderComponent();
};

export const App = observer(function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:roomName/:user_id" element={<RoomRoute />} />
        <Route path="/call-end" element={<CallEndScreen />} />
      </Routes>
    </Router>
  );
});

export default App;
