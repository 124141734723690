// import axios from "axios";
//
// export interface KnuhDataRequest {
//   data_type: string;
//   data: {
//     timestamp: string;
//     text: string;
//   };
//   user_id: string;
// }
//
// interface KnuhDataResponseItem {
//   timestamp: string;
//   text: string;
// }
//
// interface KnuhDataResponseData {
//   data_type: string;
//   user_id: string;
//   data: KnuhDataResponseItem[];
// }
//
// export interface KnuhDataResponse {
//   success: boolean;
//   data: KnuhDataResponseData;
// }
//
// export const postKnuhData = async (
//   roomKey: string,
//   requestData: KnuhDataRequest
// ): Promise<KnuhDataResponse> => {
//   const url = `https://videoapi.test.soldoc.co.kr/knuh/${roomKey}/data/`;
//
//   const config = {
//     method: "post",
//     maxBodyLength: Infinity,
//     url: url,
//     headers: {
//       "Content-Type": "application/json",
//     },
//     data: JSON.stringify(requestData),
//   };
//
//   try {
//     const response = await axios(config);
//     return response.data;
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       console.error("Axios error response data:", error.response?.data);
//       throw new Error(error.response?.data?.message || "Failed to post data");
//     } else {
//       console.error("Unexpected error:", error);
//       throw new Error("An unexpected error occurred");
//     }
//   }
// };

import axios from "axios";
import { config } from "~/utils/agora.config";
import { appStore } from "~/stores/app.store";
import { memoStore } from "~/stores/memo.store";

export interface Memo {
  //   id: number;
  text: string;
  timestamp: string;
}

export const getTimeZoneDate = () => {
  // getTimezoneOffset()은 현재 시간과의 차이를 분 단위로 반환한다.
  const offset = new Date().getTimezoneOffset() * 60000;

  return new Date(Date.now() - offset);
};

export const postKnuhData = async (text: string) => {
  await memoStore.memoContents.push({
    timestamp: getTimeZoneDate().toISOString(),
    text: text,
  });
  postMemo(memoStore.memoContents);
};

export const postMemo = async (data: any, dataType = "MEMO") => {
  try {
    const postData = await axios.post(
      config.soldocUrl + "knuh/" + appStore.roomName + "/data/",
      {
        data_type: dataType,
        data: data,
        user_id: appStore.userId,
      }
    );
  } catch (error) {
    console.log("메모에러" + error);
  }
};

export const getMemo = async () => {
  try {
    const getData = await axios.get(
      config.soldocUrl +
        "knuh/" +
        appStore.roomName +
        "/data/?user_id=" +
        appStore.userId
    );
    const data: Memo[] = getData.data.data.data;
    const memos = data.map((item) => ({
      // id: item.id,
      text: item.text,
      timestamp: item.timestamp,
    }));
    memoStore.setMemoContents(memos);
  } catch (error) {
    console.log("메모에러" + error);
  }
};

export const deleteMemo = async (timestamp: string) => {
  const memoContents = await memoStore.memoContents.filter(
    (data) => data.timestamp !== timestamp
  );
  await memoStore.setMemoContents(memoContents);
  postMemo(memoStore.memoContents);
};
export const patchMemo = async (
  timestamp: string,
  text: string,
  dataType: string
) => {
  await memoStore.memoContents.map((data) => {
    if (data.timestamp === timestamp) data.text = text;
  });
  postMemo(memoStore.memoContents, dataType);
};

//혹시 위에코드가 안될시 아래코드로 테스트
// import axios from "axios";
// import { appStore } from "~/stores/app.store";
// import { config } from "~/utils/agora.config";
//
// export const postKnuhData = async (text: string, dataType: string) => {
//   const postData = {
//     data_type: dataType,
//     data: {
//       timestamp: new Date().toISOString(),
//       text: text,
//     },
//     user_id: appStore.uid,
//   };
//
//   try {
//     console.log("보내는 데이터:", JSON.stringify(postData, null, 2)); // 요청 데이터를 콘솔에 출력하여 확인
//     await axios.post(
//       `${config.soldocUrl}knuh/${appStore.roomName}/data/`,
//       postData,
//     );
//     console.log("데이터 전송 성공");
//   } catch (error: any) {
//     if (error.response) {
//       console.log("메모에러 상태 코드:", error.response.status);
//       console.log("메모에러 응답 데이터:", error.response.data);
//     } else {
//       console.log("메모에러 메시지:", error.message);
//     }
//   }
// };
