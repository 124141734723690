import { RemoteVideoTrack, LocalVideoTrack } from "agora-rtc-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { appStore } from "~/stores/app.store";

const ShareScreenContainer = styled.div`
  object-fit: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
`;

export const ShareScreenTracks = observer(function ShareScreenTracks() {
  const { shareScreen } = appStore;
  const { localVideoTrack, remoteVideoTrack, enabled } = shareScreen;

  if (!enabled && !remoteVideoTrack) {
    return null;
  }

  return (
    <ShareScreenContainer>
      {enabled && localVideoTrack ? (
        <LocalVideoTrack
          play
          track={localVideoTrack}
          videoPlayerConfig={{ fit: "contain" }}
        />
      ) : (
        remoteVideoTrack && (
          <RemoteVideoTrack
            play
            track={remoteVideoTrack}
            videoPlayerConfig={{ fit: "contain" }}
          />
        )
      )}
    </ShareScreenContainer>
  );
});
