import React, { useRef } from "react";
import styled, { keyframes } from "styled-components";
import { CSSTransition } from "react-transition-group";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0.7;
  }
  to {
    opacity: 0;
  }
`;

const StateToastContainer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  border-radius: 50px;
  padding: 4px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
  z-index: 9999;
  user-select: none;
  animation: ${fadeIn} 0.5s ease-in-out forwards;

  &.fade-exit {
    animation: ${fadeOut} 0.5s ease-in-out forwards;
  }
`;

interface StateToastProps {
  children: React.ReactNode;
  show: boolean;
}

export const StateToast = ({ children, show }: StateToastProps) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      in={show}
      timeout={500}
      classNames="fade"
      unmountOnExit
      nodeRef={nodeRef}
    >
      <StateToastContainer ref={nodeRef}>{children}</StateToastContainer>
    </CSSTransition>
  );
};
