import { useEffect, useRef } from "react";
import styled from "styled-components";
import { createUI } from "@netless/fastboard";
import { whiteBoardStore } from "~/stores/white-board.store";

const WhiteBoardContainer = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
`;

export const WhiteBoard = () => {
  const appRef = useRef<HTMLDivElement>(null);
  const fastboardRef = useRef(whiteBoardStore.fastboard);

  useEffect(() => {
    if (whiteBoardStore.fastboard && appRef.current) {
      const uiInstance = createUI(fastboardRef.current, appRef.current);
      uiInstance.update({ theme: "dark" });
      whiteBoardStore.ui = uiInstance;

      return () => {
        uiInstance.destroy();
        whiteBoardStore.ui = null;
      };
    }
  }, []);

  return <WhiteBoardContainer id="app" ref={appRef}></WhiteBoardContainer>;
};
