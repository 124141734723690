import React, { useEffect, useState } from "react";
import { LocalUser, RemoteUser } from "agora-rtc-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { appStore } from "~/stores/app.store";
import { ReactComponent as CameraIconOff } from "~/assets/images/videocam_off.svg";
import { whiteBoardStore } from "~/stores/white-board.store";

const SmallScreenContainer = styled.div`
  width: 320px;
  height: 180px;
  background-color: #1d1d1d;
  position: absolute;
  bottom: 24px;
  right: 32px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.4);
  user-select: none;
  overflow: hidden;
  border-radius: 8px;
`;

const SmallCamCover = styled.div`
  display: flex;
  width: 320px;
  height: 180px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  flex-shrink: 0;
  background: var(--Foundation-Black-black-9, #454545);

  svg {
    color: #8c8c8c;
    width: 48px;
    height: 48px;
  }
`;

const CamCoverText = styled.div`
  color: var(--Foundation-Black-black-6, #bfbfbf);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 16px;
  font-weight: 400;
`;

const UserId = styled.span`
  position: absolute;
  /* bottom: 5px; */
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
`;

export const SmallScreen = observer(() => {
  const { localUser } = appStore.users;
  const { remoteUsers } = appStore.users;
  const { shareScreen } = appStore;
  const isShareScreenEnabled =
    (shareScreen.enabled && shareScreen.localVideoTrack) ||
    shareScreen.remoteVideoTrack;
  const isWhiteBoardEnabled = whiteBoardStore.whiteBoardState;
  const [showVideo, setShowVideo] = useState(false);

  // 로컬 사용자의 카메라 상태에 따라 showVideo 상태를 업데이트
  useEffect(() => {
    if (localUser?.cameraOn) {
      const timeout = setTimeout(() => setShowVideo(true), 500);
      return () => clearTimeout(timeout);
    } else {
      setShowVideo(false);
    }
  }, [localUser?.cameraOn]);

  if (!localUser) return null;
  return (
    <SmallScreenContainer className="SmallScreenContainer">
      {/* 화면공유, 화이트보드 기능이 활성화되면 리모트 유저를 렌더링*/}
      {isShareScreenEnabled || isWhiteBoardEnabled ? (
        remoteUsers.length > 0 ? (
          remoteUsers.map((user) =>
            user.cameraOn ? (
              <RemoteUser
                key={user.uid}
                playAudio={user.micOn}
                playVideo={user.cameraOn}
                user={user.rtcUser}
              >
                <UserId>{user.name}</UserId>
              </RemoteUser>
            ) : (
              // 리모트 유저가 카메라를 off 하면 캠커버 렌더링
              <SmallCamCover key={user.uid}>
                <CameraIconOff />
                <CamCoverText>
                  상대방이 화면 송출을 <br /> 일시정지했습니다.
                </CamCoverText>
              </SmallCamCover>
            )
          )
        ) : (
          // 리모트 유저가 없으면 캠커버 렌더링
          <SmallCamCover>
            <CameraIconOff />
            <CamCoverText>상대가 없습니다.</CamCoverText>
          </SmallCamCover>
        )
      ) : showVideo ? (
        // 화면공유, 화이트보드 기능이 비활성화 상태일 때 로컬 유저를 렌더링
        <LocalUser
          audioTrack={localUser.micTrack}
          videoTrack={localUser.cameraTrack}
          micOn={localUser.micOn}
          cameraOn={localUser.cameraOn}
          playVideo={localUser.cameraOn}
        >
          <UserId>{localUser.name}</UserId>
        </LocalUser>
      ) : (
        <SmallCamCover>
          <CameraIconOff />
          <CamCoverText>화면 송출을 일시정지 했습니다.</CamCoverText>
        </SmallCamCover>
      )}
    </SmallScreenContainer>
  );
});
