import { makeAutoObservable, runInAction } from "mobx";

// 상태 토스트 메시지를 관리하는 클래스
class StateToastStore {
  message: string | null = null; // 표시할 메시지
  showToast: boolean = false; // 토스트 메시지 표시 여부
  timeoutId: NodeJS.Timeout | null = null; // 타임아웃 ID

  constructor() {
    // MobX 상태 관리를 위해 자동으로 관찰 가능한 상태로 만듭니다.
    makeAutoObservable(this);
  }

  /**
   * 상태 토스트 메시지를 표시하는 함수
   * @param message - 표시할 메시지
   */
  showStateToast = (message: string): void => {
    // 기존 타임아웃이 있으면 제거
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // 상태를 업데이트하여 메시지를 표시
    runInAction(() => {
      this.message = message;
      this.showToast = true;
    });

    // 2초 후에 메시지를 제거
    this.timeoutId = setTimeout(() => {
      runInAction(() => {
        this.showToast = false;
      });
    }, 3000);
  };
}

// StateToastStore의 인스턴스를 생성하여 내보냅니다.
export const stateToastStore = new StateToastStore();
