import { styled } from "styled-components";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #262626;
`;

const LoadingText = styled.div`
  font-size: 24px;
  color: #fff;
`;

/**
 * @returns 로딩 컴포넌트
 */
export const Loading = () => {
  return (
    <LoadingContainer>
      <LoadingText>Loading...</LoadingText>
    </LoadingContainer>
  );
};
