import { observer } from "mobx-react-lite";
import { videoFitStore } from "~/stores/video-fit.store";
import { ControlsRatioContainer, RatioButton } from "./ControlsRatioStyles";

/**
 * 비디오 크기 비율을 설정하는 컨트롤 컴포넌트
 */
export const ControlsRatio = observer(() => {
  /**
   * 비디오 크기를 설정하는 함수
   * @param width - 비디오 너비
   * @param height - 비디오 높이
   */
  const setVideoDimensions = (width: string, height: string) => {
    videoFitStore.setVideoWidth(width, height);
  };

  return (
    <ControlsRatioContainer>
      <RatioButton
        selected={videoFitStore.videoWidth === "30%"}
        onClick={() => setVideoDimensions("30%", "30%")}
      >
        30%
      </RatioButton>
      <RatioButton
        selected={videoFitStore.videoWidth === "50%"}
        onClick={() => setVideoDimensions("50%", "50%")}
      >
        50%
      </RatioButton>
      <RatioButton
        selected={videoFitStore.videoWidth === "100%"}
        onClick={() => setVideoDimensions("100%", "100%")}
      >
        100%
      </RatioButton>
    </ControlsRatioContainer>
  );
});
