import { styled } from "styled-components";

export const ControlsFeatureContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 10px 0;
  gap: 15px;
  flex: 2;
  user-select: none;
`;
