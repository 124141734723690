import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { MicIconAnimation } from "~/components/atoms/mic-icon-animation/MicIconAnimation";
import { appStore } from "~/stores/app.store";

const StyledModalCam = styled.div`
  width: 100%;
  height: 270px;
  background-color: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CameraPreview = styled.div<{ $isLoading: boolean }>`
  width: 100%;
  height: 100%;
  ${(props) =>
    props.$isLoading
      ? css`
          opacity: 0;
        `
      : css`
          opacity: 1;
        `}
  transition: opacity 1s;

  .agora_video_player {
    border-radius: 24px;
    background-color: "#262626";
  }
`;

const LoadingCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 10;
`;

const MicControlContainer = styled.div`
  position: absolute;
  width: 56px;
  height: 56px;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalCam = observer(function ModalCam() {
  const { localUser } = appStore.users;
  const [isLoading, setIsLoading] = useState(true);

  // 디바이스 트랙 로딩
  useEffect(() => {
    const requestCameraAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        console.log("마이크, 카메라 접근 권한이 허용되었습니다.");
        localUser?.loadDevices();
      } catch (error) {
        console.error("마이크, 카메라 접근 권한이 거부되었습니다:", error);
      }
    };

    if (localUser) {
      // 마이크, 카메라 접근 권한 요청
      requestCameraAccess();
    }
  }, [localUser]);

  // 마이크를 변경하면 변경된 마이크 트랙 세팅
  useEffect(() => {
    if (localUser && localUser.micId) {
      localUser.createLocalMicTrack(localUser.micId).then(() => {
        localUser.setMic(true);
      });
    }
  }, [localUser, localUser?.micId]);

  // 카메라를 변경하면 변경된 카메라 트랙 세팅
  useEffect(() => {
    if (localUser && localUser.cameraId) {
      setIsLoading(true);
      localUser.createLocalCameraTrack(localUser.cameraId).then(() => {
        localUser.setCamera(true);
        localUser.previewLocalCameraTrack("camera-preview").then(() => {
          const timeout = setTimeout(() => {
            // 카메라가 준비된 후 딜레이 후 로딩 상태를 false로 설정
            setIsLoading(false);
          }, 500); // 500ms 딜레이
          return () => clearTimeout(timeout); // 클린업 함수로 타임아웃 정리
        });
      });
    }
  }, [localUser, localUser?.cameraId]);

  // agora-video-track에 스타일 강제 적용(background-color)
  useEffect(() => {
    const interval = setInterval(() => {
      const element = document.querySelector("#camera-preview > div");
      if (element) {
        (element as HTMLElement).style.backgroundColor = "#262626";
        clearInterval(interval); // 스타일 적용 후 인터벌 제거
      }
    }, 100); // 100ms 간격으로 체크
    return () => clearInterval(interval); // 클린업 함수로 인터벌 정리
  }, []);

  return (
    <StyledModalCam>
      {/* 로딩 커버 */}
      {isLoading && <LoadingCover>Camera Loading...</LoadingCover>}
      {/* 마이크 시각화 */}
      <MicControlContainer>
        {localUser?.micOn && (
          <MicIconAnimation
            className="modal"
            audioTrack={localUser.micTrack}
            micOn
          />
        )}
      </MicControlContainer>
      {/* 카메라 미리보기 */}
      <CameraPreview id="camera-preview" $isLoading={isLoading} />
    </StyledModalCam>
  );
});
