import styled from "styled-components";

export const MicIconAnimationContainer = styled.div`
  position: absolute;
  z-index: 10;
  overflow: hidden;
  width: 48px;
  height: 48px;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  border-radius: 50%;
  color: #fff;
  background: #808080;
  transition: background-color 0.4s;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MicBars = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 24px;
  gap: 4px;
`;

// 자주 변하는 스타일 속성을 attrs 메서드를 사용하여 처리
export const MicBar = styled.div.attrs<{ height: number }>((props) => ({
  style: {
    height: `${props.height}px`,
  },
}))<{ height: number }>`
  width: 4px;
  background-color: white;
  border-radius: 2px;
  transition: height 0.3s ease;
`;
