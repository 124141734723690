import React from "react";
import styled from "styled-components";
import { ReactComponent as Exit } from "../../../assets/images/btn_close_normal.svg";

const ExitIcon = styled(Exit)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  &:hover {
    path {
      fill: white; /* 마우스를 올렸을 때 배경색 변경 */
    }
  }
`;

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  user-select: none;
`;

const DialogContainer = styled.div`
  position: relative;
  padding: 3px 24px 24px 24px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #59595c;
  background: #262626;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.4);
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const CancelButton = styled.button<{ $backgroundColorProps?: string }>`
  color: white;
  background-color: ${(props) => props.$backgroundColorProps || "transparent"};
  font-size: 18px;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
  display: flex;
  width: 120px;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  border: 1px solid #737375; /* 외각선 설정 */
  border-radius: 8px;
  cursor: pointer;
`;

const CheckButton = styled.button`
  all: unset; /* 기본 스타일 제거 */
  color: white;
  background-color: #f12828;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  width: 120px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
`;
const DialogText = styled.p`
  color: white;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
  text-align: center;
  margin-top: 45px;
  margin-bottom: 30px;
`;

interface DeleteConfirmationDialogProps {
  cancelButtonText: string; // 취소 버튼의 텍스트
  checkButtonText: string; // 확인 버튼의 텍스트
  onConfirm: () => void;
  onCancel: () => void;
  cancelBackgroundColor?: string;
  children: React.ReactNode;
  onleave: () => void;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  cancelButtonText,
  checkButtonText,
  onConfirm,
  onCancel,
  children,
  cancelBackgroundColor,
  onleave,
}) => {
  return (
    <DialogOverlay>
      <DialogContainer>
        <ExitIcon onClick={onCancel} />
        <DialogText>{children}</DialogText>
        <ButtonContainer>
          <CancelButton
            $backgroundColorProps={cancelBackgroundColor}
            onClick={onleave}
          >
            {cancelButtonText}
          </CancelButton>
          <CheckButton onClick={onConfirm}>{checkButtonText}</CheckButton>
        </ButtonContainer>
      </DialogContainer>
    </DialogOverlay>
  );
};

export default DeleteConfirmationDialog;
