import type {
  IAgoraRTCRemoteUser,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  UID,
} from "agora-rtc-react";
import { makeAutoObservable } from "mobx";

/** 원격 사용자를 나타내는 클래스 */
export class MyRemoteUser {
  uid: UID; // 사용자 ID
  name: string; // 사용자 이름
  rtcUser: IAgoraRTCRemoteUser; // Agora RTC 원격 사용자 객체
  cameraOn: boolean; // 카메라 상태 (켜짐/꺼짐)
  micOn: boolean; // 마이크 상태 (켜짐/꺼짐)
  videoTrack?: IRemoteVideoTrack; // 원격 비디오 트랙
  audioTrack?: IRemoteAudioTrack; // 원격 오디오 트랙

  /**
   * MyRemoteUser 생성자
   * @param rtcUser - Agora RTC 원격 사용자 객체
   */
  constructor(rtcUser: IAgoraRTCRemoteUser, name?: string) {
    this.uid = rtcUser.uid; // 사용자 ID 설정
    this.name = name || String(rtcUser.uid); // 원격 사용자 이름 설정
    this.rtcUser = rtcUser; // 원격 사용자 객체 설정
    this.micOn = rtcUser.hasAudio; // 마이크 상태 설정
    this.cameraOn = rtcUser.hasVideo; // 카메라 상태 설정
    this.audioTrack = rtcUser.audioTrack; // 원격 오디오 트랙 설정
    this.videoTrack = rtcUser.videoTrack; // 원격 비디오 트랙 설정

    makeAutoObservable(this);
  }

  /**
   * 원격 사용자의 상태를 업데이트하는 함수
   * @param rtcUser - 업데이트할 Agora RTC 원격 사용자 객체
   */
  update(rtcUser: IAgoraRTCRemoteUser, name: string): void {
    this.rtcUser = rtcUser; // 원격 사용자 객체 업데이트
    this.name = name; // 원격 사용자 이름 업데이트
    this.micOn = rtcUser.hasAudio; // 마이크 상태 업데이트
    this.cameraOn = rtcUser.hasVideo; // 카메라 상태 업데이트
    this.audioTrack = rtcUser.audioTrack; // 원격 오디오 트랙 업데이트
    this.videoTrack = rtcUser.videoTrack; // 원격 비디오 트랙 업데이트
  }
}
