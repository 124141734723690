import styled from "styled-components";

export const ButtonContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const Button = styled.button<{ selected: boolean; disabled: boolean }>`
  width: 48px;
  height: 48px;
  background-color: ${(props) => (props.selected ? "#262626" : "#262626")};
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 0.7)};
  transition: opacity 0.3s;

  &:hover {
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  }

  svg {
    color: ${(props) => (props.selected ? "#008DEF" : "#D9D9D9")};
  }
`;
