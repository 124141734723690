import axios from "axios";
import { appStore } from "~/stores/app.store";
import { config } from "~/utils/agora.config";

export interface ServiceResponse {
  success: boolean;
  data: {
    service_list: string[];
  };
}

export const getCurrentService = async (): Promise<ServiceResponse | void> => {
  const { roomName } = appStore;

  try {
    const response = await axios.get<ServiceResponse>(
      config.soldocUrl + "agora/" + roomName + "/service/"
    );
    return response.data;
  } catch (error) {
    // console.log("에러");
    console.log(error);
  }
};
