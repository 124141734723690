import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { useState } from "react";
import { whiteBoardStore } from "~/stores/white-board.store";
import { recordingStore } from "~/stores/recording.store";
import { appStore } from "~/stores/app.store";
import { rtmStore, RTMMessage } from "~/stores/rtm.store";
import { stateToastStore } from "~/stores/state-toast.store";
import { cloudRecordingStop } from "~/apis/room/SoldocServer";

const DisableFeatureContainer = styled.div`
  position: absolute;
  top: 60px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const DisableFeatureButton = styled.div<{ disabled: boolean }>`
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0px 16px;
  background-color: ${({ disabled }) => (disabled ? "#a0a0a0" : "#59595c")};
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s;
  margin-bottom: 8px;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? "#a0a0a0" : "#7a7a7d")};
  }
`;

const DisableFeatureText = styled.div`
  width: auto;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

export const DisableFeature = observer(() => {
  const { shareScreen } = appStore;
  const { enabled } = shareScreen;
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleRecordingStop = async () => {
    setButtonDisabled(true);
    stateToastStore.showStateToast(
      "녹화된 영상을 저장 중입니다 잠시만 기다려주세요."
    );
    // 녹화 중지 API 호출
    await cloudRecordingStop();
    // RTM 메시지 전송: 녹화 중지
    recordingStore.sendRecordingMessage("stop");
    stateToastStore.showStateToast(
      "녹화된 영상은 경북대 병원 서버에 저장됩니다."
    );
    // 녹화 상태를 false로 설정
    recordingStore.setRecordingState(false);
    setButtonDisabled(false);
  };
  const handleShareScreenDisable = async () => {
    stateToastStore.showStateToast("화면공유를 비활성화합니다.");
    shareScreen.disable();
    shareScreen.sendShareScreenMessage("disable");
  };

  const handleWhiteBoardLeave = async () => {
    stateToastStore.showStateToast("화이트보드를 비활성화 합니다.");
    await whiteBoardStore.leave();
    whiteBoardStore.sendWhiteBoardMessage("leave");
  };

  return (
    <DisableFeatureContainer>
      {/* 녹화 종료 버튼: 녹화가 활성화되어 있고 녹화 비활성화 버튼이 활성화되지 않은 경우 */}
      {recordingStore.recordingState && (
        <DisableFeatureButton
          disabled={isButtonDisabled}
          onClick={handleRecordingStop}
        >
          <DisableFeatureText>녹화 종료</DisableFeatureText>
        </DisableFeatureButton>
      )}
      {/* 화면 공유 해제 버튼: 화면 공유가 활성화된 경우 */}
      {enabled && (
        <DisableFeatureButton
          disabled={shareScreen.disableShareScreenButton}
          onClick={isButtonDisabled ? undefined : handleShareScreenDisable}
        >
          <DisableFeatureText>공유 해제</DisableFeatureText>
        </DisableFeatureButton>
      )}
      {/* 화이트보드 종료 버튼 */}
      {whiteBoardStore.whiteBoardState && (
        <DisableFeatureButton
          disabled={whiteBoardStore.disableWhiteBoardButton}
          onClick={
            whiteBoardStore.disableWhiteBoardButton
              ? undefined
              : handleWhiteBoardLeave
          }
        >
          <DisableFeatureText>화이트보드 종료</DisableFeatureText>
        </DisableFeatureButton>
      )}
    </DisableFeatureContainer>
  );
});
