import styled from "styled-components";

interface MemoBodyProps {
  children: React.ReactNode; // children 속성을 React.ReactNode으로 정의
}

const Memo = styled.div`
  width: 278px;
  height: 72px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 최대 3줄까지 보이도록 설정 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* 잘린 텍스트에 ... 표시 */
  box-sizing: border-box;
`;

export const MemoContent: React.FC<MemoBodyProps> = ({ children }) => {
  return <Memo>{children}</Memo>;
};
