import styled from "styled-components";

interface ScrollProps {
  width?: string;
  height?: string;
}

export const Scroll = styled.div<ScrollProps>`
  width: ${(props) => props.width || "6px"};
  height: ${(props) => props.height || "100%"};
  overflow: hidden;

  &::-webkit-scrollbar {
    width: ${(props) => props.width || "6px"};
  }

  &::-webkit-scrollbar-track {
    background-color: #454547;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a0;
  }
`;

export const TextAreaScroll = styled.textarea<ScrollProps>`
  width: ${(props) => props.width || "6px"};
  height: ${(props) => props.height || "100%"};
  overflow: hidden;

  &::-webkit-scrollbar {
    width: ${(props) => props.width || "6px"};
  }

  &::-webkit-scrollbar-track {
    background-color: #454547;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a0;
  }
`;
