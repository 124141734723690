import axios, { AxiosRequestConfig } from "axios";
import { config } from "~/utils/agora.config";

interface AcquireResponse {
  createTs: number;
  status: string;
  taskId: string;
}

export const rttQuery = async (
  taskId: string,
  builderToken: string,
): Promise<AcquireResponse | null> => {
  const customerID = process.env.REACT_APP_AGORA_RTC_CUSTOMER_ID!;
  const customerSecret = process.env.REACT_APP_AGORA_RTC_CUSTOMER_SECRET!;
  const appID = process.env.REACT_APP_AGORA_RTC_APP_ID!;

  const url = `${config.agoraUrl}projects/${appID}/rtsc/speech-to-text/tasks/${taskId}?builderToken=${builderToken}`;

  const configRtt: AxiosRequestConfig = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(`${customerID}:${customerSecret}`)}`,
    },
    data: "",
    transformRequest: [
      (data: any, headers: any) => {
        headers["Content-Type"] = "application/json"; // Content-Type 헤더를 명시적으로 설정
        return data;
      },
    ],
  };

  try {
    const response = await axios(configRtt);
    return response.data as AcquireResponse;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        console.log("RTT is disabled");
      } else {
        console.error("Axios error response data:", error.response?.data);
      }
      return null;
    } else {
      console.error("Unexpected error:", error);
      return null;
    }
  }
};
