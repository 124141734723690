import axios from "axios";
import { appStore } from "~/stores/app.store";
import { config } from "~/utils/agora.config";
import { rttStore } from "~/stores/rtt.store";
import { getRoomInformation } from "~/apis/room/getRoomInformation";
import { stateToastStore } from "~/stores/state-toast.store";
import { rtmStore } from "~/stores/rtm.store";

export const joinRoom = async (
  roomName: string,
  userId: string
): Promise<{ success: boolean; message: string }> => {
  try {
    const response = await axios.post(
      config.soldocUrl + "rooms/" + roomName + "/",
      {
        user_id: userId,
      }
    );

    if (response.status === 200) {
      response.data.data.tokens.forEach((item: any) => {
        if (item.classification === "RTC") {
          appStore.setRtc(item.token, item.uid);
        } else if (item.classification === "RTM") {
          appStore.setRtm(item.token, item.uid);
        } else if (item.classification === "RTT") {
          // rtt TaskId, BuilderToken 이 존재하면 rtt 상태를 체크한다.
          rttStore.checkRttState(item.uid, item.token);
        }
      });
      // console.log(response.data.data.tokens);
      return { success: true, message: "Room joined successfully" };
    } else {
      return {
        success: false,
        message: "RTC RTM 토큰이 제대로 발급되지 않았습니다",
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage =
        error.response?.data.data?.message || "Unknown error";
      return { success: false, message: errorMessage };
    }
    return { success: false, message: "An unexpected error occurred" };
  }
};

export const cloudRecordingStart = async (): Promise<boolean> => {
  try {
    const { roomName, userId } = appStore;

    await axios.post(config.soldocUrl + "agora/" + roomName + "/tokens/", {
      classification: "CLOUD_RECORDING",
      user_id: userId,
    });
    // console.log("녹화 시작");
    return true;
  } catch (error) {
    // console.log("녹화 에러");
    return false;
  }
};

export const cloudRecordingStop = async (): Promise<void> => {
  const { roomName } = appStore;
  try {
    const response = await axios.post(
      config.soldocUrl + "agora/" + roomName + "/cloud-recording/"
    );
    console.log(response);
  } catch (error) {
    console.log(error);
  }
};
export const leaveRoom = async () => {
  try {
    const { roomName } = appStore;
    const response = await axios.post(
      config.soldocUrl + "agora/" + roomName + "/close/"
    );
    console.log(response);
  } catch (error) {
    console.log("에러");
    console.log(error);
  }
};

//시간 확인해서 자동으로 나가게
export const timer = async (onEnd: any) => {
  const { roomName } = appStore;
  const getRoominfo = await getRoomInformation(roomName);
  const setTime = getRoominfo.data.date_time;
  const endTime =
    new Date(setTime).getTime() + getRoominfo.data.life_time * 60000;
  const alertTime = 60000 * 3; // 5분
  const intervalTime = 10000; // 20초마다 체크

  const updateRemainingTime = () => {
    const currentTime = new Date().getTime();
    const timeLeft = endTime - currentTime;

    if (timeLeft <= 0) {
      clearInterval(intervalId);
      stateToastStore.showStateToast("통화가 종료되었습니다. ");
      if (onEnd) {
        leaveRoom();
        appStore.leave();
        rtmStore.leave();
        onEnd(); // 페이지 이동 콜백 호출
      }
    } else if (timeLeft <= alertTime) {
      // 10분 남았을 때 알림
      stateToastStore.showStateToast(
        `방이 ${Math.ceil(timeLeft / 60000)}분 후에 종료됩니다`
      );
    }
  };

  // 초기 호출
  updateRemainingTime();

  // 주기적으로 남은 시간 업데이트
  const intervalId = setInterval(updateRemainingTime, intervalTime);
};
