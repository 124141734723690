import { AgoraRTCProvider } from "agora-rtc-react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { appStore } from "~/stores/app.store";
import { RTMMessage, rtmStore } from "~/stores/rtm.store";
import { Subtitle } from "~/components/organisms/subtitle/Subtitle";
import { styled } from "styled-components";
import { VideoRecording } from "~/components/organisms/video-recording/VideoRecording";
import { reaction } from "mobx";
import { whiteBoardStore } from "~/stores/white-board.store";
import { recordingStore } from "~/stores/recording.store";
import { rttStore } from "~/stores/rtt.store";

const ScreenRecordingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RecordingSubtitle = styled.div`
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: -80px;
`;
export const ScreenRecording = observer(function RecordingRoom() {
  const { shareScreen } = appStore;
  const { localUser } = appStore.users;
  const { roomName, user_id } = useParams();

  useEffect(() => {
    const joinChannels = async () => {
      if (roomName && user_id === process.env.REACT_APP_BOT_SECRET_KEY!) {
        try {
          await appStore.join(roomName, 123);
          await rtmStore.join(roomName, "123");
          await rttStore.getRttUsers();
        } catch (error) {
          console.error(error);
          alert("방 참여에 실패했습니다.");
        }
      } else {
        alert("방 참여에 실패했습니다.");

        return;
      }
    };

    joinChannels();
  }, [roomName, user_id]);

  // rtmMessage의 변경을 감지하여 whiteBoard 혹은 recording 핸들러 호출
  useEffect(() => {
    const disposer = reaction(
      () => rtmStore.rtmMessage,
      (message: RTMMessage | null) => {
        if (!message) return;
        if (message.type === "whiteboard") {
          whiteBoardStore.handleWhiteBoardRTMMessage(message);
        } else if (message.type === "recording") {
          recordingStore.handleRecordingRTMMessage(message);
        } else if (message.type === "share-screen") {
          shareScreen.handleShareScreenRTMMessage(message);
        }
      }
    );
    return () => disposer();
  }, [shareScreen]);

  // 녹화봇이 들어오면 바로 퍼블리시
  useEffect(() => {
    const publishLocalUserTracks = () => {
      if (localUser && user_id === process.env.REACT_APP_BOT_SECRET_KEY) {
        localUser.publishTracks();
      } else {
        setTimeout(publishLocalUserTracks, 1000); // 1초마다 확인
      }
    };

    publishLocalUserTracks();
  }, [localUser, user_id]);

  return (
    appStore.client && (
      <AgoraRTCProvider client={appStore.client}>
        {/* RTC Publish */}
        {localUser?.isPublished && (
          <ScreenRecordingContainer>
            {/* 화면녹화 컴포넌트 - 비활성 */}
            {/* <VideoRecording /> */}
            {/* 자막 컴포넌트 */}
            <RecordingSubtitle>
              <Subtitle />
            </RecordingSubtitle>
          </ScreenRecordingContainer>
        )}
      </AgoraRTCProvider>
    )
  );
});
