import { RemoteUser } from "agora-rtc-react";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import styled from "styled-components";
import { appStore } from "~/stores/app.store";
import { ReactComponent as CameraIconOff } from "~/assets/images/videocam_off.svg";
import { ShareScreenTracks } from "~/components/organisms/share-screen/ShareScreenTracks";
import { StateToast } from "~/components/atoms/toast/StateToast";
import { stateToastStore } from "~/stores/state-toast.store";
import { WhiteBoard } from "~/components/organisms/white-board/WhiteBoard";
import { whiteBoardStore } from "~/stores/white-board.store";
import { videoFitStore } from "~/stores/video-fit.store";

const StyledLargeScreen = styled.div`
  flex: 1;
  background-color: #1d1d1d;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RemoteUserContainer = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

const LargeCamCover = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 30%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: var(--Foundation-Black-black-9, #454545);
  user-select: none;

  svg {
    color: #8c8c8c;
    width: 48px;
    height: 48px;
  }
`;

const CamCoverText = styled.div`
  color: var(--Foundation-Black-black-6, #bfbfbf);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 16px;
  font-weight: 400;
`;

const UserId = styled.span`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(59, 58, 58, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
`;

interface LargeScreenProps {
  children?: ReactNode;
}

export const LargeScreen = observer(({ children }: LargeScreenProps) => {
  const { remoteUsers } = appStore.users;
  const { shareScreen } = appStore;
  const isShareScreenEnabled =
    (shareScreen.enabled && shareScreen.localVideoTrack) ||
    shareScreen.remoteVideoTrack;
  const isWhiteBoardEnabled = whiteBoardStore.whiteBoardState;

  return (
    <StyledLargeScreen>
      {isShareScreenEnabled && isWhiteBoardEnabled ? (
        <>
          <ShareScreenTracks />
          <WhiteBoard />
        </>
      ) : isShareScreenEnabled ? (
        <ShareScreenTracks />
      ) : isWhiteBoardEnabled ? (
        <WhiteBoard />
      ) : remoteUsers.length > 0 ? (
        remoteUsers.map((user) =>
          user.cameraOn ? (
            <RemoteUserContainer
              className="remote-user"
              key={user.uid}
              width={videoFitStore.videoWidth}
              height={videoFitStore.videoHeight}
            >
              <RemoteUser
                playAudio={user.micOn}
                playVideo={user.cameraOn}
                user={user.rtcUser}
              >
                <UserId>{user.name}</UserId>
              </RemoteUser>
            </RemoteUserContainer>
          ) : (
            <LargeCamCover key={user.uid}>
              <CameraIconOff />
              <CamCoverText>
                상대방이 화면 송출을 <br /> 일시정지했습니다.
              </CamCoverText>
            </LargeCamCover>
          )
        )
      ) : (
        <LargeCamCover>
          <CameraIconOff />
          <CamCoverText>상대가 없습니다.</CamCoverText>
        </LargeCamCover>
      )}
      {children}
      {stateToastStore.message && (
        <StateToast show={stateToastStore.showToast}>
          {stateToastStore.message}
        </StateToast>
      )}
    </StyledLargeScreen>
  );
});
