import { styled } from "styled-components";
import { ReactComponent as RecordIconBase } from "~/assets/images/radio_button_checked.svg";
import { ReactComponent as ScreenShareIcon } from "~/assets/images/present_to_all.svg";
import { ReactComponent as WhiteBoardIcon } from "~/assets/images/border_color.svg";
import { observer } from "mobx-react-lite";
import { appStore } from "~/stores/app.store";
import { whiteBoardStore } from "~/stores/white-board.store";
import { recordingStore } from "~/stores/recording.store";

const StyledFeatureState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FeatureStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
const StyledRecordIcon = styled(RecordIconBase)`
  color: #eb4a4a;
`;

const StyledScreenShareIcon = styled(ScreenShareIcon)`
  color: #008def;
`;

const StyledWhiteBoardIcon = styled(WhiteBoardIcon)`
  color: #008def;
`;

const FeatureStateText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
`;

export const FeatureState = observer(() => {
  const { shareScreen } = appStore;
  return (
    <StyledFeatureState>
      {recordingStore.recordingState && (
        <FeatureStateContainer>
          <StyledRecordIcon />
          <FeatureStateText>녹화중</FeatureStateText>
        </FeatureStateContainer>
      )}
      {(shareScreen.enabled ||
        shareScreen.remoteVideoTrack ||
        shareScreen.localVideoTrack) && (
        <FeatureStateContainer>
          <StyledScreenShareIcon />
          <FeatureStateText>화면공유 중</FeatureStateText>
        </FeatureStateContainer>
      )}
      {whiteBoardStore.whiteBoardState && (
        <FeatureStateContainer>
          <StyledWhiteBoardIcon />
          <FeatureStateText>화이트보드 사용 중</FeatureStateText>
        </FeatureStateContainer>
      )}
    </StyledFeatureState>
  );
});
