import styled from "styled-components";

export const Logo = styled.img`
  position: absolute;
  top: 36px;
  left: 40px;
  width: 100px;
  height: 27px;
  user-select: none;
`;

export const ModalContainer = styled.div`
  width: 480px;
  height: 614px;
  background-color: #262626;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
