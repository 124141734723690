import { observer } from "mobx-react-lite";
import { styled } from "styled-components";
import { FeatureState } from "~/components/molecules/status-window-elements/feature-state/FeatureState";
import { DeviceState } from "~/components/molecules/status-window-elements/device-state/DeviceState";
import { DisableFeature } from "~/components/molecules/status-window-elements/disable-feature/DisableFeature";

const StatusWindowContainer = styled.div`
  width: auto;
  height: 48px;
  background: var(--Foundation-Black-black-9, #454545);
  padding: 0px 10px 0px 10px;
  display: flex;
  position: absolute;
  z-index: 10;
  top: 36px;
  right: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  user-select: none;
  /* & > *:not(:last-child):not(:only-child) {
    margin-right: 10px;
  } */
`;

export const StatusWindow = observer(() => (
  <StatusWindowContainer>
    {/* 기능 상태 컴포넌트 */}
    <FeatureState />
    {/* 디바이스 상태 컴포넌트 */}
    <DeviceState />
    {/* 기능 종료 버튼 컴포넌트 */}
    <DisableFeature />
  </StatusWindowContainer>
));
