import { makeAutoObservable } from "mobx";
import { Memo } from "~/apis/knuh/postKnuhData";

/**
 * 메모 상태를 관리하는 클래스
 */
class MemoStore {
  memoEnable: boolean = false; // 메모 활성화 여부를 나타냄
  memoContents: Memo[] = []; // 메모 내용을 저장하는 배열
  memoUpdate: string = ""; // 메모 업데이트를 추적하는 카운터

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 메모 활성화 상태를 토글하는 함수
   */
  toggleMemo = (): void => {
    this.memoEnable = !this.memoEnable; // 메모 활성화 상태를 토글
  };

  /**
   * 메모 활성화 상태를 토글하는 함수
   */
  getMemoContents = (): void => {
    this.memoEnable = !this.memoEnable; // 메모 활성화 상태를 토글
  };

  /**
   * 메모 내용을 설정하는 함수
   * @param memoContents - Memo 객체 배열
   */
  setMemoContents = (memoContents: Memo[]): void => {
    this.memoContents = memoContents; // 메모 내용을 업데이트
  };

  /**
   * 메모 업데이트 카운터를 설정하는 함수
   * @param memoUpdate - 새로운 업데이트 카운터 값
   */
  setMemoUpdate = (memoUpdate: string): void => {
    this.memoUpdate = memoUpdate; // 메모 업데이트 카운터를 업데이트
  };
}

export const memoStore = new MemoStore();
