import styled from "styled-components";
import React, { useState } from "react";
import DeleteConfirmationDialog from "@components/atoms/memo/MemoDeleteDialog";
// import { deleteMemo } from "~/apis/memo/Memo";
import { stateToastStore } from "~/stores/state-toast.store";
import { useNavigate } from "react-router-dom";
import { leaveRoom } from "~/apis/room/SoldocServer";
import { appStore } from "~/stores/app.store";
import { RTMMessage, rtmStore } from "~/stores/rtm.store";

const ExitButton = styled.button`
  width: 78px;
  height: 40px;
  flex-shrink: 0;
  color: #fff;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: "Pretendard", sans-serif;
  cursor: pointer;
  background: #f12828;
  border-radius: 8px;
  margin-left: 25px;
  /* Button1 */
  font-size: 18px;

  &:hover {
    background-color: red;
  }

  &:focus {
    background-color: red;
  }
`;

export const ExitPage = () => {
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate(); // useNavigate 훅 사용

  const handleExitClick = () => {
    setShowDialog(true);
  };

  const sendRttMessage = async (action: "exitRoom") => {
    const message = {
      type: "exit",
      action: action,
      data: {},
    } as RTMMessage;

    await rtmStore.sendMessage(message);
  };

  const handleConfirmExit = () => {
    sendRttMessage("exitRoom");
    appStore.handleConfirmExit("exit");
    navigate("/call-end"); // 클릭 시 /call-end 페이지로 리디렉션
  };
  const handleCancelExit = () => {
    setShowDialog(false);
  };

  const handleLeaveRoom = () => {
    appStore.handleConfirmExit("leave");
    navigate("/");
  };

  return (
    <>
      <ExitButton onClick={handleExitClick}>나가기</ExitButton>
      {showDialog && (
        <DeleteConfirmationDialog
          cancelButtonText="방 나가기"
          checkButtonText="종료 후 나가기"
          onConfirm={handleConfirmExit}
          onCancel={handleCancelExit}
          onleave={handleLeaveRoom}
          cancelBackgroundColor="#27a3fc"
        >
          방에서 나가겠습니까?
          <br />방 종료 시 모든 서비스가 종료됩니다.
        </DeleteConfirmationDialog>
      )}
    </>
  );
};
