import { styled } from "styled-components";

export const ControlsContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 80px;
  background-color: #141414;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  border-top: 1px solid #353535;
`;
