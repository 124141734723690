import axios from "axios";

import { appStore } from "~/stores/app.store";
import { AuthHeader } from "~/apis/recording/AuthHeader";
import { config } from "~/utils/agora.config";

// Base64 인코딩 함수

//agora appId추가
const url =
  config.agoraUrl +
  "apps/" +
  process.env.REACT_APP_AGORA_RTC_APP_ID +
  "/cloud_recording/acquire";

export const acquire = async () => {
  const { roomName } = appStore;
  const { uid } = appStore;

  try {
    const authHeader = AuthHeader();
    // 요청 본문 데이터
    const requestData = {
      cname: roomName,
      uid: uid?.toString(),
      clientRequest: {
        scene: 1,
        resourceExpiredHour: 24,
      },
    };

    const response = await axios.post(url, requestData, {
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
    });

    const { resourceId } = response.data;
    // console.log("acquire토큰 발급완료");

    return resourceId;
  } catch (error) {
    console.error("Error logging in:", error);
  }
};
