import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { config } from "~/utils/agora.config";

interface GetRoomInformationResponse {
  success: boolean;
  data: RoomInformation;
}

interface RoomInformation {
  date_time: string;
  max_capacity: number;
  life_time: number;
  users: User[];
  current_participants_count: number;
}

export interface User {
  user_id: string;
  uid: string;
  user_name: string;
}

export const getRoomInformation = async (
  roomKey: string
): Promise<GetRoomInformationResponse> => {
  const url = `${config.soldocUrl}rooms/${roomKey}/`;

  const configRoom: AxiosRequestConfig = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios(configRoom);
    return response.data as GetRoomInformationResponse;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error response data:", error.response?.data);
      throw new Error(
        error.response?.data?.message || "Failed to get room information"
      );
    } else {
      console.error("Unexpected error:", error);
      throw new Error("An unexpected error occurred");
    }
  }
};
