import { RtcTokenBuilder } from "agora-access-token";
import AgoraRTC, { IAgoraRTCClient, UID } from "agora-rtc-react";
import { makeAutoObservable } from "mobx";
import { config } from "~/utils/agora.config";
import { Users } from "./users.store";
import { ShareScreen } from "./share-screen.store";
import { RTMMessage, rtmStore } from "~/stores/rtm.store";
import { leaveRoom } from "~/apis/room/SoldocServer";
import { useNavigate } from "react-router-dom";

// Agora RTC SDK의 로그 레벨을 설정합니다. 여기서는 경고 수준으로 설정됩니다.
AgoraRTC.setLogLevel(/* warning */ 2);

class AppStore {
  client: IAgoraRTCClient | null = null; // Agora RTC 클라이언트 객체
  roomName: string = ""; // 현재 방 이름
  userId: string = ""; // 현재 유저 아이디 이름
  users = new Users(); // Users 스토어 인스턴스
  shareScreen = new ShareScreen(); // ShareScreen 스토어 인스턴스
  rtcToken: string = "";
  rtcUid: string = "";
  rtmToken: string = "";
  rtmUid: string = "";

  setRtc(newRtcToken: string, newUid: string) {
    this.rtcToken = newRtcToken;
    this.rtcUid = newUid;
  }

  setRtm(newRtmToken: string, newUid: string) {
    this.rtmToken = newRtmToken;
    this.rtmUid = newUid;
  }

  /**
   * AppStore 생성자
   * Users 스토어에 shareScreen UID를 추가하고 MobX 상태 관리를 설정합니다.
   */
  constructor() {
    this.users.localUIDs.push(this.shareScreen.uid); // ShareScreen UID를 Users 스토어에 추가
    makeAutoObservable(this); // MobX 상태 관리 설정
  }

  /**
   * 현재 클라이언트의 UID를 반환하는 getter.
   */
  get uid(): UID | undefined {
    return this.client?.uid;
  }

  /**
   * 방 이름을 설정하는 메서드.
   * @param roomName - 방 이름
   */
  setRoomName = (roomName: string): void => {
    this.roomName = roomName;
  };

  /**
   * 유저 아이디를 설정하는 메서드.
   * @param roomName - 방 이름
   */
  setUserId = (userId: string | number): void => {
    this.userId = String(userId);
  };

  /**
   * 주어진 방 이름으로 Agora RTC 클라이언트에 참여하는 비동기 메서드.
   * @param roomName - 방 이름
   * @param uid - 사용자 ID
   */
  join = async (roomName: string, user_id: UID): Promise<void> => {
    try {
      this.setRoomName(roomName);
      this.setUserId(user_id);
      let token: string = "";
      if (user_id === 123) {
        token = this._generateToken(roomName, user_id);
      } else {
        token = appStore.rtcToken;
      }
      const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
      await client.join(config.appId, roomName, token, Number(appStore.rtcUid));
      await this._updateClient(client);
      console.log("RTC client uid:", client.uid);
      console.log("RTC join successful");
    } catch (error) {
      console.error("Failed to join the room:", error);
    }
  };

  /**
   * 현재 클라이언트를 방에서 나가게 하는 비동기 메서드.
   * 현재 사용되지 않음.
   */
  leave = async (): Promise<void> => {
    try {
      this.users.dispose();
      await this.shareScreen.dispose(); // ShareScreen 리소스 해제
      const client = this.client;
      if (client) {
        await client.leave();
        console.log("rtc client leave");
        this._updateClient(null);
      }
    } catch (error) {
      console.error("Failed to leave the room:", error);
    }
  };

  handleConfirmExit = (exitOrLeave: string) => {
    this.users.localUser?.setCamera(false);
    this.users.localUser?.setMic(false);
    appStore.leave();
    rtmStore.leave();
    console.log(exitOrLeave);
    if (exitOrLeave === "exit") {
      leaveRoom();
    }
  };
  handleExitRTMMessage = async (message: RTMMessage): Promise<void> => {
    if (message.action === "exitRoom") {
      this.handleConfirmExit("exit");
    }
  };

  /**
   * roomName과 agora.config에서 값을 가져와 토큰을 생성하는 private 메서드
   * @param roomName - 방 이름
   * @param uid - 사용자 ID
   * @returns 생성된 토큰
   */
  private _generateToken = (roomName: string, uid: UID): string => {
    return RtcTokenBuilder.buildTokenWithUid(
      config.appId,
      config.appCertificate,
      roomName,
      Number(uid),
      config.role,
      config.privilegeExpiredTs
    );
  };

  /**
   * 클라이언트를 업데이트하고, Users 클래스에 클라이언트를 전달하는 private 메서드.
   * @param client - 업데이트할 클라이언트
   */
  private _updateClient = async (
    client: IAgoraRTCClient | null
  ): Promise<void> => {
    this.client = client;
    this.users.updateClient(client);
    this.shareScreen.updateMainClient(client); // ShareScreen 클라이언트 업데이트
  };
}

export type { AppStore };

export const appStore = new AppStore();
