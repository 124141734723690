import styled from "styled-components";
import { MemoContent } from "@components/atoms/memo/MemoContent";
import { MemoTimeContent } from "@components/atoms/memo/MemoContentTime";
import { memoStore } from "~/stores/memo.store";
import { observer } from "mobx-react-lite";
import { Scroll } from "~/components/molecules/memo-elements/MemoScroll";
import MemoIcon from "@components/atoms/memo/MemoIcon";
import { useEffect, useRef } from "react";
import MemoEdit from "@components/atoms/memo/MemoEdit";

const MemoContainer = styled(Scroll)`
  flex: 1;
  width: 328px;
  height: 100%;
  overflow-y: auto;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MemoItem = styled.div<{ $isUpdating: boolean }>`
  width: 310px;
  height: 124px;
  display: flex;
  flex-direction: column;
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  box-sizing: border-box;
  background: ${(props) => (props.$isUpdating ? "#59595C" : "transparent")};
  border: 1px solid
    ${(props) => (props.$isUpdating ? "#008DEF" : "transparent")};

  &:hover {
    background: #59595c; /* 호버 시 배경색 변경 */
  }
`;

const IconContainer = styled.div`
  width: 278px;
  display: flex;
`;

export const MemoContentContainer = observer(() => {
  //스크롤 자동으로 내리기 (스무스 추가예정)
  const memoContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (memoContainerRef.current) {
      memoContainerRef.current.scrollTop =
        memoContainerRef.current.scrollHeight;
    }
  }, [memoStore.memoContents.length]);

  return (
    <MemoContainer ref={memoContainerRef}>
      {memoStore.memoContents.map((memo) => (
        <MemoItem
          key={memo.timestamp}
          $isUpdating={memoStore.memoUpdate === memo.timestamp}
        >
          {memoStore.memoUpdate === memo.timestamp ? (
            <MemoEdit timestamp={memo.timestamp}>{memo.text}</MemoEdit>
          ) : (
            <>
              <MemoContent>{memo.text}</MemoContent>
              <IconContainer>
                <MemoTimeContent>{memo.timestamp}</MemoTimeContent>
                <MemoIcon timestamp={memo.timestamp} />
              </IconContainer>
            </>
          )}
        </MemoItem>
      ))}
    </MemoContainer>
  );
});
