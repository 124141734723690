import { styled } from "styled-components";
import { MemoContentContainer } from "~/components/molecules/memo-elements/MemoContentContainer";
import { MemoPostBox } from "~/components/molecules/memo-elements/MemoPostBox";

const MemoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  padding: 36px 40px 24px 32px;
  gap: 16px;
  flex-shrink: 0;
  border-left: 1px solid #454545;
  background: #262626;
  overflow-y: auto; /* 세로 스크롤만 활성화 */
  overflow-x: hidden; /* 가로 스크롤 숨기기 */
  box-sizing: border-box;
`;

export const Memo = () => {
  return (
    <MemoContainer>
      <MemoContentContainer />
      <MemoPostBox />
    </MemoContainer>
  );
};
