import { styled } from "styled-components";

const CallEndScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
`;

export const CallEndScreen = () => {
  return (
    <CallEndScreenContainer>통화가 종료되었습니다.</CallEndScreenContainer>
  );
};
