import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { LargeScreen } from "~/components/molecules/video-call-elements/large-screen/LargeScreen";
import { SmallScreen } from "~/components/molecules/video-call-elements/small-screen/SmallScreen";
import { StatusWindow } from "~/components/organisms/status-window/StatusWindow";
import { Memo } from "../memo/Memo";
import { rttStore } from "~/stores/rtt.store";
import { memoStore } from "~/stores/memo.store";
import React from "react";

const VideoCallContainer = styled.div<{ height: string }>`
  position: absolute;
  display: flex;
  width: 100%;
  height: ${({ height }) => height};
  flex: 1;
  justify-content: space-between;
`;

export const VideoCall = observer(() => {
  const hasSubtitle = rttStore.rttComponentState;
  const containerHeight = hasSubtitle
    ? "calc(100% - 230px)"
    : "calc(100% - 80px)";

  return (
    <VideoCallContainer height={containerHeight}>
      {/* 리모트 유저 비디오를 렌더링하는 컴포넌트 */}
      <LargeScreen>
        {/* 로컬 유저 비디오를 렌더링하는 컴포넌트 */}
        <SmallScreen />
        {/* 기능 및 디바이스 상태를 렌더링하는 컴포넌트 */}
        <StatusWindow />
      </LargeScreen>
      {/* 메모 컴포넌트 */}
      {memoStore.memoEnable && <Memo />}
    </VideoCallContainer>
  );
});
