import React, { useState } from "react";
import { ReactComponent as WhiteBoardIcon } from "~/assets/images/border_color.svg";
import { Tooltip } from "../../tooltip/Tooltip";
import { observer } from "mobx-react-lite";
import { whiteBoardStore } from "~/stores/white-board.store";
import { rtmStore, RTMMessage } from "~/stores/rtm.store";
import { stateToastStore } from "~/stores/state-toast.store";
import { ButtonContainer, Button } from "./WhiteBoardButtonStyles";

/** WhiteBoardButton 컴포넌트는 화이트보드 상태를 제어하는 버튼을 나타냅니다. */
export const WhiteBoardButton = observer(() => {
  // 툴팁의 가시성을 상태로 관리합니다.
  const [tooltipVisible, setTooltipVisible] = useState(false);

  // 툴팁을 보이게 합니다.
  const showTooltip = () => setTooltipVisible(true);
  // 툴팁을 숨깁니다.
  const hideTooltip = () => setTooltipVisible(false);

  // 화이트보드 토글 버튼을 클릭했을 때 호출되는 함수입니다.
  const handleWhiteBoardToggle = async () => {
    try {
      // 화이트보드 버튼을 비활성화
      whiteBoardStore.setDisableWhiteBoardButton(true);

      // 화이트보드가 활성화되지 않은 경우 화이트보드 활성화
      if (whiteBoardStore.whiteBoardState === false) {
        // 화이트보드 준비중 메세지 전송
        await whiteBoardStore.sendWhiteBoardMessage("ready");
        // 화이트보드 시작 토스트 메세지
        stateToastStore.showStateToast("화이트보드를 활성화 합니다.");
        // 화이트보드에 입장하고 상태를 업데이트
        await whiteBoardStore.join();
        // 화이트보드 시작 메시지 전송
        await whiteBoardStore.sendWhiteBoardMessage("start");

        // 화이트보드가 이미 활성화된 경우 비활성화
      } else {
        // 화이트보드 중지 토스트 메세지
        stateToastStore.showStateToast("화이트보드를 비활성화 합니다.");
        // 화이트보드 중지 메세지 전송
        await whiteBoardStore.sendWhiteBoardMessage("leave");
        // 화이트보드를 나가고 상태를 업데이트
        await whiteBoardStore.leave();
      }
      // 2초뒤에 화이트보드 버튼을 활성화합니다.
      setTimeout(() => {
        whiteBoardStore.setDisableWhiteBoardButton(false);
      }, 2000);
    } catch (error) {
      // 2초뒤에 화이트보드 버튼을 활성화합니다.
      setTimeout(() => {
        whiteBoardStore.setDisableWhiteBoardButton(false);
      }, 2000);
    }
  };

  return (
    <ButtonContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {/* 툴팁을 렌더링합니다. */}
      <Tooltip message="화이트보드" visible={tooltipVisible} />
      {/* 화이트보드 버튼을 렌더링합니다. */}
      <Button
        selected={whiteBoardStore.whiteBoardState}
        onClick={handleWhiteBoardToggle}
        disabled={whiteBoardStore.disableWhiteBoardButton}
      >
        <WhiteBoardIcon />
      </Button>
    </ButtonContainer>
  );
});
