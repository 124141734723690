import { ControlsLogoContainer, Logo } from "./ControlsLogoStyles";
import logo from "~/assets/images/logo.png";

/**
 * 로고를 표시하는 컨트롤 컴포넌트
 */
export const ControlsLogo = () => {
  return (
    <ControlsLogoContainer>
      <Logo src={logo} alt="Logo" />
    </ControlsLogoContainer>
  );
};
