import type {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  UID,
} from "agora-rtc-react";
import AgoraRTC from "agora-rtc-react";
import { makeAutoObservable, runInAction } from "mobx";

/** 로컬 사용자 클래스를 정의 */
export class MyLocalUser {
  readonly client: IAgoraRTCClient; // Agora RTC 클라이언트
  uid: UID; // 사용자 ID
  name: string; // 사용자 이름
  micOn: boolean = false; // 마이크 상태
  cameraOn: boolean = false; // 카메라 상태
  micTrack?: IMicrophoneAudioTrack; // 마이크 오디오 트랙
  cameraTrack?: ICameraVideoTrack; // 카메라 비디오 트랙
  micDevices: MediaDeviceInfo[] = []; // 사용 가능한 마이크 디바이스 목록
  cameraDevices: MediaDeviceInfo[] = []; // 사용 가능한 카메라 디바이스 목록
  micId: string = ""; // 선택된 마이크 디바이스 ID
  cameraId: string = ""; // 선택된 카메라 디바이스 ID
  isPublished: boolean = false; // 트랙 퍼블리시 상태

  /**
   * MyLocalUser 생성자
   * @param client - Agora RTC 클라이언트 객체
   * @param uid - 사용자 ID
   * @param name - 사용자 이름
   */
  constructor({
    client,
    uid,
    name,
  }: {
    client: IAgoraRTCClient;
    uid: UID;
    name: string;
  }) {
    this.client = client;
    this.uid = uid;
    this.name = name;

    makeAutoObservable(this); // MobX 상태 관리 설정
    this.loadDevices(); // 초기화 시 디바이스 목록을 로드합니다.
  }
  /**
   * 마이크 상태를 설정하는 메서드.
   * 마이크가 켜져 있지 않거나 트랙이 없는 경우 새 마이크 트랙을 생성합니다.
   * 이후 마이크 트랙의 상태를 업데이트합니다.
   * @param micOn - 마이크를 켜거나 끌지 여부를 나타내는 불리언 값.
   */
  setMic = async (micOn: boolean): Promise<void> => {
    runInAction(() => {
      this.micOn = micOn;
    });

    try {
      if (micOn && !this.micTrack) {
        await this.createLocalMicTrack();
      }
      if (this.micTrack) {
        await this.micTrack.setEnabled(micOn);
      }
    } catch (error) {
      console.error("Failed to set mic state:", error);
    }
  };

  /**
   * 카메라 상태를 설정하는 메서드.
   * 카메라가 켜져 있지 않거나 트랙이 없는 경우 새 카메라 트랙을 생성합니다.
   * 이후 카메라 트랙의 상태를 업데이트합니다.
   * @param cameraOn - 카메라를 켜거나 끌지 여부를 나타내는 불리언 값.
   */
  setCamera = async (cameraOn: boolean): Promise<void> => {
    runInAction(() => {
      this.cameraOn = cameraOn;
    });

    try {
      if (cameraOn && !this.cameraTrack) {
        await this.createLocalCameraTrack();
      }
      if (this.cameraTrack) {
        await this.cameraTrack.setEnabled(cameraOn);
      }
    } catch (error) {
      console.error("Failed to set camera state:", error);
    }
  };

  /**
   * 로컬 마이크 트랙을 생성하는 메서드.
   * @param microphoneId - 선택된 마이크 디바이스 ID.
   * @returns 생성된 마이크 오디오 트랙
   */
  createLocalMicTrack = async (
    microphoneId?: string
  ): Promise<IMicrophoneAudioTrack> => {
    if (this.client) {
      if (this.micTrack) {
        this.micTrack.stop();
        this.micTrack.close();
        this.micTrack = void 0;
      }
      const track = await AgoraRTC.createMicrophoneAudioTrack({
        microphoneId: microphoneId,
        bypassWebAudio: true,
      });
      this.updateLocalMicTrack(track);
    }
    if (this.micTrack) {
      return this.micTrack;
    }
    return Promise.reject();
  };

  /**
   * 로컬 마이크 트랙을 업데이트하는 메서드.
   * @param track - 업데이트할 마이크 오디오 트랙
   */
  updateLocalMicTrack = (track: IMicrophoneAudioTrack): void => {
    runInAction(() => {
      this.micTrack = track;
    });
    if (this.micTrack) {
      this.micTrack.play = () => {}; // 마이크 트랙을 재생하지 않도록 설정
    }
  };

  /**
   * 로컬 카메라 트랙을 생성하는 메서드.
   * @param cameraId - 선택된 카메라 디바이스 ID.
   * @returns 생성된 카메라 비디오 트랙
   */
  createLocalCameraTrack = async (
    cameraId?: string
  ): Promise<ICameraVideoTrack> => {
    if (this.client) {
      if (this.cameraTrack) {
        this.cameraTrack.stop();
        this.cameraTrack.close();
        this.cameraTrack = void 0;
      }
      const track = await AgoraRTC.createCameraVideoTrack({
        cameraId: cameraId || this.cameraId,
        // encoderConfig: {
        //   width: 1920,
        //   height: 1080,
        //   frameRate: 30,
        //   bitrateMin: 1000,
        //   bitrateMax: 1000,
        // },
        encoderConfig: "720p_auto",
      });
      this.updateLocalCameraTrack(track);
    }
    if (this.cameraTrack) {
      return this.cameraTrack;
    }
    return Promise.reject();
  };

  /**
   * 로컬 카메라 트랙을 업데이트하는 메서드.
   * @param track - 업데이트할 카메라 비디오 트랙
   */
  updateLocalCameraTrack = (track: ICameraVideoTrack): void => {
    runInAction(() => {
      this.cameraTrack = track;
    });
  };

  /**
   * 모달창에서 카메라 트랙을 미리보기 하는 메서드.
   * @param elementId - 미리보기를 표시할 HTML 요소의 ID
   */
  previewLocalCameraTrack = async (elementId: string): Promise<void> => {
    if (this.cameraTrack) {
      await this.cameraTrack.setEncoderConfiguration({
        width: 480,
        height: 270,
      });
      this.cameraTrack.play(elementId);
    }
  };

  /**
   * 사용 가능한 디바이스 목록을 가져오는 메서드.
   */
  loadDevices = async (): Promise<void> => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      runInAction(() => {
        this.cameraDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        this.micDevices = devices.filter(
          (device) => device.kind === "audioinput"
        );

        if (this.cameraDevices.length > 0) {
          this.cameraId = this.cameraDevices[0].deviceId;
        }
        if (this.micDevices.length > 0) {
          this.micId = this.micDevices[0].deviceId;
        }
      });
    } catch (error) {
      console.error("Error getting media devices:", error);
    }
  };

  /**
   * 선택된 오디오 디바이스 ID를 설정하는 메서드.
   * @param id - 설정할 마이크 디바이스 ID
   */
  setMicId = (id: string): void => {
    runInAction(() => {
      this.micId = id;
    });
  };

  /**
   * 선택된 비디오 디바이스 ID를 설정하는 메서드.
   * @param id - 설정할 카메라 디바이스 ID
   */
  setCameraId = (id: string): void => {
    runInAction(() => {
      this.cameraId = id;
    });
  };

  /**
   * 리소스를 해제하는 메서드.
   */
  dispose = (): void => {
    if (this.micTrack) {
      this.micTrack.stop();
      this.micTrack.close();
      this.micTrack = void 0;
    }
    if (this.cameraTrack) {
      this.cameraTrack.stop();
      this.cameraTrack.close();
      this.cameraTrack = void 0;
    }
  };

  /**
   * 트랙을 publish 하는 메서드.
   */
  publishTracks = async (): Promise<void> => {
    if (this.client) {
      if (this.micTrack) {
        await this.client.publish(this.micTrack);
      }
      if (this.cameraTrack) {
        await this.client.publish(this.cameraTrack);
      }
      runInAction(() => {
        this.isPublished = true;
      });
    }
  };

  /**
   * 퍼블리시 상태를 설정하는 메서드.
   * @param published - 퍼블리시 상태
   */
  setPublished = (published: boolean): void => {
    runInAction(() => {
      this.isPublished = published;
    });
  };
}
