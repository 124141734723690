import styled from "styled-components";

interface MemoContentTimeProps {
  children: React.ReactNode; // children 속성을 React.ReactNode으로 정의
}

const Time = styled.div`
  width: 200px;
  height: 22px;
  padding-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
  margin-right: 22px;
`;

export const MemoTimeContent: React.FC<MemoContentTimeProps> = ({
  children,
}) => {
  return <Time>{children}</Time>;
};
