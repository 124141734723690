import styled from "styled-components";
import { DialogOverlay } from "@components/atoms/memo/MemoDeleteDialog";

const ErrorMassageContainer = styled.div`
  padding: 24px;
  width: 300px;
  height: 212px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #59595c;
  background: #262626;
  //box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.4);
`;

const ErrorTitleArea = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  padding-top: 22px;
`;

const ErrorContent = styled.div`
  color: #fff;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  height: 122px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  padding-bottom: 35px;
  padding-top: 35px;
`;

const ErrorButton = styled.button`
  all: unset; /* 기본 스타일 제거 */
  display: flex;
  background-color: #595959;
  border-radius: 8px;
  width: 252px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #7a7a7d;
  }
`;

interface ErrorMassageProps {
  message?: string;
}

export const ErrorMassage = ({ message }: ErrorMassageProps) => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <DialogOverlay>
      <ErrorMassageContainer>
        <ErrorContent>{message}</ErrorContent>
        <ErrorButton onClick={handleRefresh}>확인</ErrorButton>
      </ErrorMassageContainer>
    </DialogOverlay>
  );
};
