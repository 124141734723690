import React, { useEffect, useState } from "react";
import { ReactComponent as MicIconOn } from "~/assets/images/mic.svg";
import { ReactComponent as MicIconOff } from "~/assets/images/mic_off.svg";
import { Tooltip } from "../../tooltip/Tooltip";
import { appStore } from "~/stores/app.store";
import { ButtonContainer, Button } from "./MicButtonStyles";

/** MicButton 컴포넌트는 마이크 상태를 제어하는 버튼을 나타냅니다. */
export const MicButton = () => {
  // 앱 스토어에서 localUser를 가져옵니다.
  const { localUser } = appStore.users;
  // 마이크가 켜져 있는지 여부를 상태로 관리합니다.
  const [selected, setSelected] = useState(localUser?.micOn ?? false);
  // 툴팁의 가시성을 상태로 관리합니다.
  const [tooltipVisible, setTooltipVisible] = useState(false);
  // 버튼이 비활성화 상태인지 여부를 상태로 관리합니다.
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // localUser의 마이크 상태가 변경되면 selected 상태를 업데이트합니다.
  useEffect(() => {
    setSelected(localUser?.micOn ?? true);
  }, [localUser?.micOn]);

  // 툴팁을 보이게 합니다.
  const showTooltip = () => setTooltipVisible(true);
  // 툴팁을 숨깁니다.
  const hideTooltip = () => setTooltipVisible(false);

  // 마이크 토글 버튼을 클릭했을 때 호출되는 함수입니다.
  const handleMicToggle = () => {
    if (!buttonDisabled && localUser) {
      // 버튼을 비활성화합니다.
      setButtonDisabled(true);
      // localUser의 마이크 상태를 토글합니다.
      localUser.setMic(!localUser.micOn);
      // selected 상태를 업데이트합니다.
      setSelected(!localUser.micOn);

      // 1초 후에 버튼을 다시 활성화합니다.
      setTimeout(() => {
        setButtonDisabled(false);
      }, 1000);
    }
  };

  return (
    <ButtonContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {/* 툴팁을 렌더링합니다. */}
      <Tooltip message="음소거" visible={tooltipVisible} />
      {/* 마이크 토글 버튼을 렌더링합니다. */}
      <Button
        selected={selected}
        onClick={handleMicToggle}
        disabled={buttonDisabled}
      >
        {selected ? <MicIconOn /> : <MicIconOff />}
      </Button>
    </ButtonContainer>
  );
};
