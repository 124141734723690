import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ArrowDropDown } from "~/components/atoms/arrow-drop-down/CheckSmall";
import { CheckSmall } from "~/components/atoms/check-small/CheckSmall";
import { appStore } from "~/stores/app.store";

const StyledModalOption = styled.div`
  width: 100%;
  height: 320px;
  background-color: #262626;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`;

const DeviceSelector = styled.div`
  width: 100%;
  height: 104px;

  label {
    display: block;
    color: #fff;
    margin-bottom: 12px;
  }

  .custom-select-container {
    position: relative;
    width: 100%;
    height: 48px;
    margin-bottom: 24px; // 간격을 벌림
  }

  .custom-select {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px 0px 20px;
    border-radius: 8px;
    background-color: #333;
    cursor: pointer;
    border: 1px solid #555;

    color: var(--Foundation-Black-black-1, #fff);
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .custom-select-text {
    width: 416px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .custom-select-container.open .custom-select:after {
    transform: rotate(180deg);
  }

  .custom-select-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 10px;
    background-color: #333;
    border: 1px solid #555;
    border-radius: 8px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    display: none;
  }

  .custom-select-dropdown.open {
    display: block;
  }

  .custom-select-dropdown::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .custom-select-option {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px 0px 20px;
    cursor: pointer;

    color: var(--Foundation-Black-black-1, #fff);
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }

  .custom-select-option-text {
    width: 416px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .custom-select-option:hover {
    background-color: #273f55;
  }

  .custom-select-option.selected {
    position: relative;
  }
`;

const PublishButton = styled.button`
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  border: none;
  background-color: #008def;

  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  font-variant-numeric: lining-nums tabular-nums;

  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    background-color: #27a3fc;
  }

  &:focus {
    background-color: #27a3fc;
  }

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
`;

export const ModalOption = observer(function ModalOption() {
  const { localUser } = appStore.users;
  const [isPublishable, setIsPublishable] = useState(false);
  const [cameraDropdownOpen, setCameraDropdownOpen] = useState(false);
  const [micDropdownOpen, setMicDropdownOpen] = useState(false);
  const cameraRef = useRef<HTMLDivElement>(null);
  const micRef = useRef<HTMLDivElement>(null);

  // 로컬 디바이스 마이크 트랙이 전부 준비되면 버튼을 활성화
  useEffect(() => {
    if (localUser?.micTrack) {
      setTimeout(() => {
        setIsPublishable(true);
      }, 2000);
    } else {
      setIsPublishable(false);
    }
  }, [localUser?.micTrack]);

  // 버튼을 눌러 로컬 트랙을 채널에 퍼블리시
  const handlePublish = () => {
    if (localUser) {
      localUser.publishTracks();
    }
  };

  const toggleCameraDropdown = () => {
    if (localUser?.cameraDevices && localUser?.cameraDevices.length === 1)
      return;
    setCameraDropdownOpen(!cameraDropdownOpen);
    if (!cameraDropdownOpen) {
      setMicDropdownOpen(false);
    }
  };

  const toggleMicDropdown = () => {
    if (localUser?.micDevices && localUser?.micDevices.length === 1) return;
    setMicDropdownOpen(!micDropdownOpen);
    if (!micDropdownOpen) {
      setCameraDropdownOpen(false);
    }
  };

  const handleCameraChange = (deviceId: string) => {
    localUser?.setCameraId(deviceId);
    setCameraDropdownOpen(false);
  };

  const handleMicChange = (deviceId: string) => {
    localUser?.setMicId(deviceId);
    setMicDropdownOpen(false);
  };

  // 드랍다운이 열렸을 때 다른 곳을 클릭하면 드랍다운을 비활성화
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        cameraRef.current &&
        !cameraRef.current.contains(event.target as Node) &&
        micRef.current &&
        !micRef.current.contains(event.target as Node)
      ) {
        setCameraDropdownOpen(false);
        setMicDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (localUser == null) return null;

  return (
    <StyledModalOption>
      <DeviceSelector>
        <label>카메라</label>
        <div className="custom-select-container" ref={cameraRef}>
          <div
            className={`custom-select ${
              localUser.cameraDevices.length === 0 ? "disabled" : ""
            }`}
            onClick={toggleCameraDropdown}
          >
            <div className="custom-select-text">
              {localUser.cameraDevices.find(
                (device) => device.deviceId === localUser.cameraId
              )?.label || "카메라가 연결되어 있지 않습니다."}
            </div>
            <ArrowDropDown isOpen={cameraDropdownOpen} />
          </div>
          <div
            className={`custom-select-dropdown ${
              cameraDropdownOpen ? "open" : ""
            }`}
          >
            {localUser.cameraDevices.map((device: MediaDeviceInfo) => (
              <div
                key={device.deviceId}
                className={`custom-select-option ${
                  localUser.cameraId === device.deviceId ? "selected" : ""
                }`}
                onClick={() => handleCameraChange(device.deviceId)}
              >
                <div className="custom-select-option-text">{device.label}</div>
                {localUser.cameraId === device.deviceId && <CheckSmall />}
              </div>
            ))}
          </div>
        </div>
      </DeviceSelector>

      <DeviceSelector>
        <label>오디오</label>
        <div className="custom-select-container" ref={micRef}>
          <div className="custom-select" onClick={toggleMicDropdown}>
            <div className="custom-select-text">
              {localUser.micDevices.find(
                (device) => device.deviceId === localUser.micId
              )?.label || "마이크가 연결되어 있지 않습니다."}
            </div>
            <ArrowDropDown isOpen={micDropdownOpen} />
          </div>
          <div
            className={`custom-select-dropdown ${
              micDropdownOpen ? "open" : ""
            }`}
          >
            {localUser.micDevices.map((device: MediaDeviceInfo) => (
              <div
                key={device.deviceId}
                className={`custom-select-option ${
                  localUser.micId === device.deviceId ? "selected" : ""
                }`}
                onClick={() => handleMicChange(device.deviceId)}
              >
                {device.label}
                {localUser.micId === device.deviceId && <CheckSmall />}
              </div>
            ))}
          </div>
        </div>
      </DeviceSelector>
      <PublishButton onClick={handlePublish} disabled={!isPublishable}>
        참여하기
      </PublishButton>
    </StyledModalOption>
  );
});
