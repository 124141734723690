import { appStore } from "./app.store";
import protoRoot from "~/utils/proto";
import { makeAutoObservable } from "mobx";
import { TextMessage } from "~/utils/proto/interface";
import { rttQuery } from "~/apis/rtt/rttQuery";
import { TokenUpdateRequest, updateToken } from "~/apis/agora/token-update";
import { acquireBuilderToken } from "~/apis/rtt/rttAcquireBuilderToken";
import { startRTT } from "~/apis/rtt/rttStart";
import { stopRTT } from "~/apis/rtt/rttStop";
import { RTMMessage, rtmStore } from "./rtm.store";
import { stateToastStore } from "./state-toast.store";
import { getRoomInformation } from "~/apis/room/getRoomInformation";
import { getTimeZoneDate, Memo, postMemo } from "~/apis/knuh/postKnuhData";

/** 자막 item 인터페이스 정의 */
interface TranscribeItem {
  uid: any;
  text: string;
  isFinal: boolean;
}

/**
 * RTT(Real-Time Transcription) 스토어 클래스 정의
 */
class RttStore {
  rttState: boolean = false; // RTT 기능 상태 (활성화 여부)
  rttComponentState: boolean = false; // RTT 컴포넌트 상태 (렌더링 여부)
  disableRttButton: boolean = false; // RTT 버튼 상태
  builderToken: string = ""; // RTT 토큰
  taskId: string = ""; // RTT 작업 ID
  transcribeItems: TranscribeItem[] = []; // 자막 항목 리스트
  transcribeTexts: Memo[] = [];
  rttUsers: any = null;

  constructor() {
    makeAutoObservable(this);
  }

  // RTT 사용자 정보를 가져와 설정하는 메서드
  getRttUsers = async () => {
    const roomInfo = await getRoomInformation(appStore.roomName);
    this.rttUsers = roomInfo.data.users;
  };
  /**
   * 주어진 taskId와 builderToken을 사용하여 RTT 상태를 조회하고,
   * 상태가 "IN_PROGRESS"일 경우 자막 상태를 true로 설정합니다.
   *
   * @param taskId - RTT 작업의 고유 식별자
   * @param builderToken - Builder 토큰
   * @returns Promise<void>
   */
  checkRttState = async (
    taskId: string,
    builderToken: string
  ): Promise<void> => {
    await this.setTaskId(taskId); // taskId 설정
    await this.setBuilderToken(builderToken); // builderToken 설정
    const response = await rttQuery(this.taskId, this.builderToken); // RTT 상태 조회
    if (response?.status === "IN_PROGRESS") {
      // RTT 상태가 "IN_PROGRESS"일 경우
      // 자막 기능 상태를 true로 설정
      // console.log("RTT is enabled");
      this.setRttState(true);
      this.setRttComponentState(true);
    }
  };

  /**
   * 현재 taskId와 builderToken을 사용하여 RTT 토큰을 업데이트합니다.
   *
   * @returns Promise<void>
   */
  updateRttToken = async (): Promise<void> => {
    const userId = appStore.userId?.toString();
    const requestData: TokenUpdateRequest = {
      classification: "RTT",
      uid: this.taskId,
      token: this.builderToken,
      room_key: appStore.roomName,
    };
    // RTT taskId와 builderToken을 업데이트합니다.
    await updateToken(userId, requestData);
  };

  /**
   * UID를 이름으로 변환하는 유틸리티 함수
   * @param uid - 변환할 UID
   * @returns {string} - 변환된 이름
   */
  getUserNameByUID = (uid: string): string => {
    const user = this.rttUsers?.find(
      (user: any) => String(user.uid) === String(uid)
    );
    return user ? user.user_name : uid;
  };

  /**
   * RTT 기능 상태를 설정하는 메서드
   * @param state - RTT 상태
   */
  setRttState = (state: boolean): void => {
    this.rttState = state;
  };

  /**
   * RTT 컴포넌트 상태를 설정하는 메서드
   * @param state - RTT 상태
   */
  setRttComponentState = (state: boolean): void => {
    this.rttComponentState = state;
  };

  /**
   * RTT 버튼 상태를 설정하는 메서드
   * @param state - RTT 상태
   */
  setDisableRttButton = (state: boolean): void => {
    this.disableRttButton = state;
  };

  /**
   * RTT 토큰을 설정하는 비동기 함수
   * @param builderToken - 설정할 RTT 토큰
   */
  setBuilderToken = async (builderToken: string): Promise<void> => {
    this.builderToken = builderToken;
  };

  /**
   * TaskId를 설정하는 비동기 함수
   * @param roomToken - 설정할 TaskId
   */
  setTaskId = async (taskId: string): Promise<void> => {
    this.taskId = taskId;
  };

  /**
   * RTT 토큰 획득 메서드
   * @returns {Promise<void>}
   */
  handleAcquireToken = async (): Promise<void> => {
    try {
      const uid = appStore.uid?.toString() || "";
      if (!uid) {
        throw new Error("UID is not available");
      }
      const response = await acquireBuilderToken(uid);
      // console.log("RTT Token: ", response);
      this.setBuilderToken(response.tokenName);
    } catch (err: any) {
      // console.log("Failed to acquire builder token: ", err);
    }
  };

  /**
   * RTT 시작 메서드
   * @returns {Promise<void>}
   */
  handleStartRTT = async (): Promise<void> => {
    try {
      const response = await startRTT(
        appStore.roomName,
        this.builderToken,
        appStore.uid
      );
      // console.log("RTT Started: ", response);
      this.setTaskId(response.taskId);
      this.setRttState(true);
    } catch (err: any) {
      console.error("Failed to start RTT:", err);
    }
  };

  /**
   * RTT 중단 메서드
   * @returns {Promise<void>}
   */
  handleStopRTT = async (): Promise<void> => {
    try {
      await stopRTT(this.taskId, this.builderToken);
      this.setRttState(false);
    } catch (err: any) {
      console.error("Failed to stop RTT:", err);
    }
  };

  /**
   * 스트림 메시지 핸들러 메서드
   * @param {any} msgUid - 메시지 발신자의 UID
   * @param {any} data - 수신된 데이터
   */
  handleStreamMessage = (msgUid: any, data: any): void => {
    this.dealStreamMessage(data);
  };

  /**
   * 스트림 메시지 처리 메서드
   * @param {any} data - 수신된 데이터
   */
  dealStreamMessage = (data: any): void => {
    // ProtoBuf를 사용하여 데이터 디코딩
    const MessageType = protoRoot.lookupType("Text");
    const msg = MessageType.decode(data) as unknown as TextMessage;
    const { words, data_type, trans = [], uid } = msg;
    let isFinal = false;
    let text = "";
    // 필사 기능
    if (data_type === "transcribe") {
      if (words.length) {
        words.forEach((item: any) => {
          if (item.isFinal) {
            isFinal = true;
          }
          text += item?.text;
        });
        this.updateTranscribeItem(uid, text, isFinal);
      }
    }
    // 번역 기능
    else if (data_type === "translate") {
      if (trans.length) {
        trans.forEach((item: any) => {
          item?.texts.forEach((v: any) => (text += v));
          isFinal = item?.isFinal;
        });
      }
    }
  };

  /**
   * RTT RTM 메세지 전송
   * @param action - "start", "stop" 중 하나의 액션 타입
   */
  sendRttMessage = async (action: "start" | "stop" | "disable") => {
    const message = {
      type: "rtt",
      action: action,
      data: {},
    } as RTMMessage;

    await rtmStore.sendMessage(message);
  };

  /**
   * RTM 메시지에 따라 RTT 상태를 처리하는 함수
   * @param message - 처리할 RTM 메시지
   */
  handleRttRTMMessage = async (message: RTMMessage): Promise<void> => {
    try {
      // RTT 버튼을 비활성화 합니다.
      this.setDisableRttButton(true);
      // RTT를 시작한다는 메세지를 받으면,
      if (message.action === "start" && rttStore.rttState === false) {
        // RTT 기능을 활성화 시킵니다.
        await this.handleAcquireToken();
        await this.handleStartRTT();
        // rtt taskId와 builderToken을 업데이트합니다.
        await this.updateRttToken();
      } else if (message.action === "stop") {
        // stop 메세지를 받았을때 내 컴포넌트가 on이라면 그냥 return
        if (rttStore.rttComponentState === true) {
          setTimeout(() => {
            this.setDisableRttButton(false);
            rttStore.setDisableRttButton(false);
          }, 2000);
          return;
        }
        // stop 메세지를 받았을때 내 컴포넌트가 off라면
        else if (rttStore.rttComponentState === false) {
          // 상대방에게 기능을 종료하라는 메세지를 전송합니다.
          this.sendRttMessage("disable");
          // 본인도 기능을 종료합니다.
          await rttStore.handleStopRTT();
        }
      } else if (message.action === "disable") {
        stateToastStore.showStateToast("자막 비활성화 중...");
        await rttStore.handleStopRTT();
        stateToastStore.showStateToast("자막을 비활성화 합니다.");
      }
    } catch (error) {
      setTimeout(() => {
        this.setDisableRttButton(false);
        rttStore.setDisableRttButton(false);
      }, 2000);
    }
    setTimeout(() => {
      this.setDisableRttButton(false);
      rttStore.setDisableRttButton(false);
    }, 2000);
  };

  /**
   * 필사 항목 업데이트 메서드
   * @param {any} uid - 사용자 ID
   * @param {string} text - 텍스트 내용
   * @param {boolean} isFinal - 최종 여부
   * @returns {Promise<void>}
   */
  updateTranscribeItem = async (
    uid: any,
    text: string,
    isFinal: boolean
  ): Promise<void> => {
    const last = this.transcribeItems[this.transcribeItems.length - 1];
    if (last?.isFinal) {
      this.transcribeItems.push({ uid, text, isFinal });
    } else {
      this.transcribeItems.pop();
      this.transcribeItems.push({ uid, text, isFinal });
    }
    // 완성된 문장 console.log로 출력
    // console.log("Updated transcribeItems:", text);

    // 경북대 자막 업로드
    if (isFinal) {
      // s3 저장 메소드 apis/rtt/rtt.ts
      // await uploadToS3(uid, text);
      // this.postTranscribeItem(uid, text);
      //
      // 경북대 서버 자막 전송 API
      const name = rttStore.getUserNameByUID(uid);
      this.transcribeTexts.push({
        text: `${name}: ${text}`,
        timestamp: getTimeZoneDate().toISOString(),
      });

      postMemo(this.transcribeTexts, "RTT");
    }
  };

  /**
   * 경북대학교 병원에 데이터를 전송하는 API를 호출하는 메소드
   */
  // postTranscribeItem = async (uid: any, text: string) => {
  //   const roomKey = appStore.roomName;
  //   const requestData: KnuhDataRequest = {
  //     data_type: "RTT",
  //     data: {
  //       timestamp: new Date().toISOString(),
  //       text: text,
  //     },
  //     user_id: uid,
  //   };
  //
  //   try {
  //     // const response = await postKnuhData(roomKey, requestData);
  //     // console.log("RTT post is success", response);
  //     console.log("rtt 전송 테스트", roomKey, requestData);
  //   } catch (error) {
  //     console.error("RTT post is failed", error);
  //   }
  // };
}

export type { RttStore };

export const rttStore = new RttStore();
