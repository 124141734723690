import axios from "axios";
import { config } from "~/utils/agora.config";

export interface TokenUpdateRequest {
  classification: string;
  uid: string;
  token: string;
  room_key: string;
}

interface TokenUpdateData {
  uid: string;
  token: string;
  classification: string;
}

export interface TokenUpdateResponse {
  success: boolean;
  data: TokenUpdateData;
}

export const updateToken = async (
  userId: string | undefined,
  requestData: TokenUpdateRequest,
): Promise<TokenUpdateResponse> => {
  const url = `${config.soldocUrl}agora/users/${userId}/tokens/`;

  const configUpdateToken = {
    method: "post",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(requestData),
  };

  try {
    const response = await axios(configUpdateToken);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error response data:", error.response?.data);
      throw new Error(
        error.response?.data?.message || "Failed to update token",
      );
    } else {
      console.error("Unexpected error:", error);
      throw new Error("An unexpected error occurred");
    }
  }
};
