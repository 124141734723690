import { makeAutoObservable } from "mobx";

// 비디오 크기를 관리하는 클래스
class VideoFitStore {
  videoWidth: string = "100%"; // 비디오 너비
  videoHeight: string = "100%"; // 비디오 높이

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 비디오 너비와 높이를 설정하는 함수
   * @param width - 설정할 비디오 너비
   * @param height - 설정할 비디오 높이
   */
  setVideoWidth = (width: string, height: string): void => {
    this.videoWidth = width; // 비디오 너비 업데이트
    this.videoHeight = height; // 비디오 높이 업데이트
  };
}

export const videoFitStore = new VideoFitStore();
