import type {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  UID,
} from "agora-rtc-react";
import { makeAutoObservable, observable } from "mobx";
import { MyLocalUser } from "./local-user.store";
import { MyRemoteUser } from "./remote-user.store";
import { rttStore } from "./rtt.store";
import { whiteBoardStore } from "./white-board.store";
import { getRoomInformation } from "~/apis/room/getRoomInformation";
import { appStore } from "./app.store";

/** 사용자 관리를 위한 클래스 정의 */
export class Users {
  private readonly _remoteUsersMap = observable.map<UID, MyRemoteUser>(); // 원격 사용자 목록을 저장하는 맵
  readonly localUIDs: UID[] = []; // 로컬 사용자 UID 목록
  private eventHandlers: { event: string; handler: Function }[] = []; // 이벤트 핸들러 목록

  localUser: MyLocalUser | null = null; // 로컬 사용자 객체

  /**
   * 로컬 사용자 객체를 설정하는 함수
   * @param localUser - 로컬 사용자 객체
   */
  private setLocalUser = (localUser: MyLocalUser | null): void => {
    this.localUser = localUser;
  };

  /** 원격 사용자 목록을 반환하는 getter */
  get remoteUsers(): MyRemoteUser[] {
    return [...this._remoteUsersMap.values()];
  }

  /** 클래스 생성자 */
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Agora RTC 클라이언트를 업데이트하고 이벤트 핸들러를 설정하는 함수
   * @param client - Agora RTC 클라이언트 객체
   */
  updateClient = async (client: IAgoraRTCClient | null): Promise<void> => {
    if (client && client.uid) {
      try {
        // 통화 정보를 조회하여 사용자 이름 설정
        const roomInfo = await getRoomInformation(appStore.roomName);
        const localUserInfo = roomInfo.data.users.find(
          (user) => user.uid === String(client.uid)
        );
        // 로컬 사용자 객체 생성
        const localUser = new MyLocalUser({
          client,
          uid: client.uid,
          name: localUserInfo ? localUserInfo.user_name : client.uid.toString(),
        });
        this.setLocalUser(localUser);
        // 현재 RTC 채널에 있는 원격 유저 목록을 가져와서 초기화하기
        client.remoteUsers.forEach((remoteUser) => {
          const userInfo = roomInfo.data.users.find(
            (user) => user.uid === String(remoteUser.uid)
          );
          if (userInfo) {
            const name = userInfo.user_name;
            this._updateRemoteUser(remoteUser, name, true);
          }
        });
      } catch (error) {
        console.error("Failed to get room information:", error);
      }

      // 사용자가 참여했을 때 호출되는 함수
      const handleUserJoined = async (remoteUser: IAgoraRTCRemoteUser) => {
        // 화이트보드 사용중 상대방이 들어올때 화이트보드 시작 메세지 전송
        if (whiteBoardStore.whiteBoardState === true) {
          whiteBoardStore.sendWhiteBoardMessage("start");
        }
        // 자막 사용중 상대방이 들어올때 자막 시작 메세지 전송
        if (rttStore.rttState === true) {
          rttStore.sendRttMessage("start");
        }
        if (this.localUIDs.includes(remoteUser.uid)) return;
        const roomInfo = await getRoomInformation(appStore.roomName);
        const localUserInfo = roomInfo.data.users.find(
          (user) => user.uid === String(remoteUser.uid)
        );
        if (localUserInfo) {
          const name = localUserInfo.user_name;
          this._updateRemoteUser(remoteUser, name, true);
        }
      };

      // 사용자가 떠났을 때 호출되는 함수
      const handleUserLeft = (remoteUser: IAgoraRTCRemoteUser) => {
        if (this.localUIDs.includes(remoteUser.uid)) return;
        this._deleteRemoteUser(remoteUser.uid);
      };

      // 사용자가 미디어를 게시했을 때 호출되는 함수
      const handleUserPublished = async (remoteUser: IAgoraRTCRemoteUser) => {
        if (remoteUser.uid === client.uid) return;
        if (this.localUIDs.includes(remoteUser.uid)) return;
        const roomInfo = await getRoomInformation(appStore.roomName);
        const localUserInfo = roomInfo.data.users.find(
          (user) => user.uid === String(remoteUser.uid)
        );
        if (localUserInfo) {
          const name = localUserInfo.user_name;
          this._updateRemoteUser(remoteUser, name);
        }
      };

      // 사용자가 미디어 게시를 중단했을 때 호출되는 함수
      const handleUserUnpublished = async (remoteUser: IAgoraRTCRemoteUser) => {
        if (remoteUser.uid === client.uid) return;
        if (this.localUIDs.includes(remoteUser.uid)) return;
        const roomInfo = await getRoomInformation(appStore.roomName);
        const localUserInfo = roomInfo.data.users.find(
          (user) => user.uid === String(remoteUser.uid)
        );
        if (localUserInfo) {
          const name = localUserInfo.user_name;
          this._updateRemoteUser(remoteUser, name);
        }
      };

      // 스트림 메시지를 처리하는 함수
      const handleStreamMessage = rttStore.handleStreamMessage;

      // 이벤트 핸들러 등록
      client.on("user-joined", handleUserJoined);
      client.on("user-left", handleUserLeft);
      client.on("user-published", handleUserPublished);
      client.on("user-unpublished", handleUserUnpublished);
      client.on("stream-message", handleStreamMessage);

      // 이벤트 핸들러 목록 저장
      this.eventHandlers = [
        { event: "user-joined", handler: handleUserJoined },
        { event: "user-left", handler: handleUserLeft },
        { event: "user-published", handler: handleUserPublished },
        { event: "user-unpublished", handler: handleUserUnpublished },
        { event: "stream-message", handler: handleStreamMessage },
      ];
    } else {
      this.localUser = null;
    }
  };

  /** 모든 이벤트 핸들러를 제거하고 리소스를 해제하는 함수 */
  dispose = (): void => {
    if (this.localUser?.client) {
      this.eventHandlers.forEach(({ event, handler }) => {
        this.localUser?.client.off(event, handler);
      });
      this.localUser.dispose();
      this.localUser = null;
    }

    this.remoteUsers.forEach(({ rtcUser }) => {
      rtcUser.audioTrack?.stop(); // 원격 사용자의 오디오 트랙 정지
      rtcUser.videoTrack?.stop(); // 원격 사용자의 비디오 트랙 정지
    });

    this._remoteUsersMap.clear(); // 원격 사용자 목록 초기화
  };

  /**
   * 원격 사용자를 업데이트하거나 새로운 원격 사용자를 추가하는 함수
   * @param rtcUser - 원격 사용자 객체
   * @param createIfNotExist - 존재하지 않을 경우 새로운 원격 사용자를 생성할지 여부
   */
  private _updateRemoteUser = (
    rtcUser: IAgoraRTCRemoteUser,
    name: string,
    createIfNotExist?: boolean
  ): void => {
    const user = this._remoteUsersMap.get(rtcUser.uid);
    if (user) {
      user.update(rtcUser, name); // 원격 사용자 업데이트
    } else if (createIfNotExist) {
      this._remoteUsersMap.set(rtcUser.uid, new MyRemoteUser(rtcUser, name)); // 새로운 원격 사용자 추가
    }
  };

  /**
   * 원격 사용자를 삭제하는 함수
   * @param uid - 삭제할 원격 사용자의 UID
   */
  private _deleteRemoteUser = (uid: UID): void => {
    this._remoteUsersMap.delete(uid);
  };
}
