import { AgoraRTCProvider } from "agora-rtc-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Modal } from "~/components/organisms/modal/Modal";
import { appStore } from "~/stores/app.store";
import { RTMMessage, rtmStore } from "~/stores/rtm.store";
import { Controls } from "~/components/organisms/controls/Controls";
import { VideoCall } from "~/components/organisms/video-call/VideoCall";
import { rttStore } from "~/stores/rtt.store";
import { whiteBoardStore } from "~/stores/white-board.store";
import { reaction } from "mobx";
import { Subtitle } from "~/components/organisms/subtitle/Subtitle";
import { recordingStore } from "~/stores/recording.store";
import { joinRoom, timer } from "~/apis/room/SoldocServer";
import { Loading } from "~/components/atoms/loading/Loading";
import { ErrorMassage } from "@components/atoms/errorMassage/ErrorMassage";
import { getRoomInformation } from "~/apis/room/getRoomInformation";
import { ExitPage } from "@components/atoms/exitButton/ExitButton";

const RoomContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 80px;
`;

export const Room = observer(function Room() {
  const { shareScreen } = appStore;
  const { localUser } = appStore.users;

  const { roomName, user_id } = useParams<{
    roomName: string;
    user_id: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [errorState, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); // useNavigate 훅 사용

  // roomName이 유효하면 RTC, RTM 클라이언트 채널 join
  useEffect(() => {
    const joinChannels = async () => {
      const join = await joinRoom(roomName!, user_id!);
      if (!join.success) {
        setLoading(false);
        setErrorState(true);
        setErrorMessage(join.message);
        return;
      }

      if (roomName && user_id) {
        try {
          await appStore.join(roomName, user_id);
          await rtmStore.join(roomName, user_id);
          // 현재 녹화상태를 체크
          await recordingStore.checkRecordingState();
          // 현재 통화정보를 체크(유저가 나가도 RTT에 user_name을 표시하기 위함)
          await rttStore.getRttUsers();
          setLoading(false);
          //방시간 확인후 강제퇴장
          timer(() => {
            navigate("/call-end"); // 페이지 이동
          });
        } catch (error) {
          console.error(error);
          alert("방 참여에 실패했습니다.");
          setLoading(false);
        }
      } else {
        alert("유효하지 않은 방 이름입니다.");
        setLoading(false);
      }
    };

    joinChannels();
  }, [roomName, user_id]);

  // rtmMessage의 변경을 감지하여 이벤트 핸들러 호출
  useEffect(() => {
    const disposer = reaction(
      () => rtmStore.rtmMessage,
      (message: RTMMessage | null) => {
        if (!message) return;
        if (message.type === "whiteboard") {
          whiteBoardStore.handleWhiteBoardRTMMessage(message);
        } else if (message.type === "recording") {
          recordingStore.handleRecordingRTMMessage(message);
        } else if (message.type === "share-screen") {
          shareScreen.handleShareScreenRTMMessage(message);
        } else if (message.type === "rtt") {
          rttStore.handleRttRTMMessage(message);
        } else if (message.type === "exit") {
          appStore.handleExitRTMMessage(message);
          navigate("/call-end");
        }
      }
    );
    return () => disposer();
  }, [shareScreen]);

  if (errorState) {
    return <ErrorMassage message={errorMessage} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    appStore.client && (
      <AgoraRTCProvider client={appStore.client}>
        {/* 디바이스 선택 모달 컴포넌트 */}
        {!localUser?.isPublished && <Modal />}
        {/* RTC Publish */}
        {localUser?.isPublished && (
          <RoomContainer>
            {/* 영상통화 컴포넌트 */}
            <VideoCall />
            {/* 자막 컴포넌트 */}
            {rttStore.rttComponentState && <Subtitle />}
            {/* 컨트롤러 컴포넌트*/}
            <Controls />
          </RoomContainer>
        )}
      </AgoraRTCProvider>
    )
  );
});
