import { makeAutoObservable } from "mobx";
import { RTMMessage, rtmStore } from "~/stores/rtm.store";
import { stateToastStore } from "./state-toast.store";
import { createFastboard } from "@netless/fastboard";
import { appStore } from "./app.store";
import {
  TokenRequest,
  TokenResponse,
  fetchToken,
} from "~/apis/agora/token-create";
/**
 * 화이트보드 상태 관리를 위한 클래스
 */
class WhiteBoardStore {
  whiteBoardState: boolean = false; // 화이트보드 상태 (활성화/비활성화)
  disableWhiteBoardButton: boolean = false; // 화이트보드 버튼 비활성화 상태
  roomUuid: string = ""; // 룸 UUID
  roomToken: string = ""; // 룸 토큰
  fastboard: any = null; // Fastboard 인스턴스
  ui: any = null; // UI 인스턴스

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 화이트보드 상태를 설정하는 함수
   * @param state - 설정할 화이트보드 상태 (true: 활성화, false: 비활성화)
   */
  setWhiteBoardState = (state: boolean): void => {
    this.whiteBoardState = state;
  };

  /**
   * 화이트보드 버튼 비활성화 상태를 설정하는 함수
   * @param state - 설정할 버튼 비활성화 상태 (true: 비활성화, false: 활성화)
   */
  setDisableWhiteBoardButton = (state: boolean): void => {
    this.disableWhiteBoardButton = state;
  };

  /**
   * 방 UUID를 설정하는 비동기 함수
   * @param roomUuid - 설정할 방 UUID
   */
  setRoomUuid = async (roomUuid: string): Promise<void> => {
    this.roomUuid = roomUuid;
  };

  /**
   * 방 토큰을 설정하는 비동기 함수
   * @param roomToken - 설정할 방 토큰
   */
  setRoomToken = async (roomToken: string): Promise<void> => {
    this.roomToken = roomToken;
  };

  /**
   * 토큰 응답과 커서 상태를 가져오는 함수
   * @param roomKey - 방 키
   * @returns {Promise<{ tokenResponse: TokenResponse; cursorState: boolean }>}
   */
  private getTokenResponseAndCursorState = async (
    roomKey: string
  ): Promise<{ tokenResponse: TokenResponse; cursorState: boolean }> => {
    let tokenResponse: TokenResponse;
    let cursorState: boolean;

    // 녹화봇이 화이트보드를 호출하면 remoteuser의 uid를 빌려 접속
    if (appStore.uid === 123) {
      const requestDataForRecording: TokenRequest = {
        classification: "WHITE_BOARD",
        user_id: String(appStore.users.remoteUsers[0].uid),
      };
      cursorState = false;
      tokenResponse = await fetchToken(roomKey, requestDataForRecording);
    } else {
      const requestData: TokenRequest = {
        classification: "WHITE_BOARD",
        user_id: String(appStore.userId),
      };
      cursorState = true;
      tokenResponse = await fetchToken(roomKey, requestData);
    }
    return { tokenResponse, cursorState };
  };

  /**
   * 화이트보드 룸에 입장하는 함수
   */
  join = async (): Promise<void> => {
    const roomKey = appStore.roomName;
    try {
      const { tokenResponse, cursorState } =
        await this.getTokenResponseAndCursorState(roomKey);
      const { uid, token } = tokenResponse.data[0];
      await this.setRoomUuid(uid);
      await this.setRoomToken(token);

      // 패스트보드 인스턴스 생성
      if (!this.fastboard) {
        const fastboard = await createFastboard({
          sdkConfig: {
            appIdentifier: process.env.REACT_APP_WHITEBOARD_IDENTIFIER!,
            region: "us-sv",
          },
          joinRoom: {
            uid: String(appStore.users.localUser?.name),
            uuid: this.roomUuid,
            roomToken: this.roomToken,
          },
          managerConfig: {
            cursor: cursorState,
          },
        });
        this.fastboard = fastboard;
      }
      // 패스트보드 페이지 오픈
      this.setWhiteBoardState(true);
    } catch (err: any) {
      console.error("Failed to join room", err);
    }
  };

  /**
   * 화이트보드 룸에서 나가는 함수
   */
  leave = async (): Promise<void> => {
    try {
      if (this.fastboard) {
        this.fastboard.destroy();
        this.fastboard = null;
      }
      if (this.ui) {
        this.ui.destroy();
        this.ui = null;
      }
      this.setWhiteBoardState(false);
    } catch (err: any) {
      console.error("Failed to leave room", err);
    }
  };

  /**
   * 화이트보드를 비활성화하는 함수
   */
  disabled = async (): Promise<void> => {
    try {
      // this.sdkToken = "";
      this.roomUuid = "";
      this.roomToken = "";
      if (this.fastboard) {
        this.fastboard.destroy();
        this.fastboard = null;
      }
      if (this.ui) {
        this.ui.destroy();
        this.ui = null;
      }
      this.setWhiteBoardState(false);
    } catch (err: any) {
      console.error("Failed to disable whiteboard", err);
    }
  };

  /**
   * 화이트보드 RTM 메세지 전송
   * @param action - "ready", "start", "leave" 중 하나의 액션 타입
   */
  sendWhiteBoardMessage = async (action: "ready" | "start" | "leave") => {
    const message = {
      type: "whiteboard",
      action: action,
      data: {},
    } as RTMMessage;

    await rtmStore.sendMessage(message);
  };

  /**
   * RTM 메시지에 따라 화이트보드 상태를 처리하는 함수
   * @param message - 처리할 RTM 메시지
   */
  handleWhiteBoardRTMMessage = async (message: RTMMessage): Promise<void> => {
    try {
      // 화이트보드를 준비한다는 메세지를 받으면,
      if (message.action === "ready") {
        // 화이트보드 버튼을 비활성화 상태로 설정합니다.
        this.setDisableWhiteBoardButton(true);
        // 사용자에게 화이트보드가 준비 중이라는 알람을 표시합니다.
        stateToastStore.showStateToast("화이트보드 준비 중입니다.");
      }

      // 화이트보드를 활성화하라는 메시지를 받으면,
      else if (message.action === "start") {
        // 이미 화이트보드가 활성화 되어있으면 return
        if (this.whiteBoardState === true) {
          return;
        }
        // 사용자에게 화이트보드가 활성화된다는 알림을 표시합니다.
        stateToastStore.showStateToast("화이트보드를 활성화 합니다.");
        // 화이트보드 기능을 활성화합니다.
        await this.join();
        // 2초뒤에 화이트보드 버튼을 활성화합니다.
        setTimeout(() => {
          this.setDisableWhiteBoardButton(false);
        }, 2000);

        // 화이트보드를 비활성화하라는 메시지를 받으면,
      } else if (message.action === "leave") {
        // 화이트보드 버튼을 비활성화 상태로 설정합니다.
        this.setDisableWhiteBoardButton(true);
        // 사용자에게 화이트보드가 비활성화된다는 알림을 표시합니다.
        stateToastStore.showStateToast("화이트보드를 비활성화 합니다.");
        // 화이트보드 기능을 비활성화합니다.
        await this.leave();
        // 2초뒤에 화이트보드 버튼을 활성화합니다.
        setTimeout(() => {
          this.setDisableWhiteBoardButton(false);
        }, 2000);

        // 화이트보드를 완전히 비활성화하라는 메시지를 받으면,
      } else if (message.action === "disable") {
        // 화이트보드를 완전히 비활성화합니다.
        await this.disabled();
      }
    } catch (error) {
      // 2초뒤에 화이트보드 버튼을 활성화합니다.
      setTimeout(() => {
        this.setDisableWhiteBoardButton(false);
      }, 2000);
    }
  };
}

export const whiteBoardStore = new WhiteBoardStore();
