import styled from "styled-components";

export const ButtonContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const Button = styled.button<{ selected: boolean }>`
  width: 48px;
  height: 48px;
  background-color: ${(props) => (props.selected ? "#262626" : "#262626")};
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  svg {
    color: ${(props) => (props.selected ? "#008DEF" : "#D9D9D9")};
  }
`;
