import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { rttStore } from "~/stores/rtt.store";
import { reaction } from "mobx";

// StyledSubtitleContainer 내부에서 조건부 스타일링 적용
const StyledSubtitleContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 150px;
  overflow: hidden;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7); /* 반투명 배경 */
  color: white;
  padding: 10px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* 자막을 아래쪽에 배치 */
  align-items: center;
  bottom: 80px;
`;

const SubtitleContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* 텍스트 그림자 */

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Subtitle = observer(function Subtitle() {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const disposer = reaction(
      () => rttStore.transcribeItems.length,
      () => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }
    );
    return () => disposer();
  }, []);

  // 자막을 최대 4개까지 표시하고 나머지는 위로 올라가도록 조정
  const displayedItems = rttStore.transcribeItems.slice(-4);

  return (
    <StyledSubtitleContainer ref={containerRef}>
      {displayedItems.map((item, index) => (
        <SubtitleContents key={index} className="item">
          {rttStore.getUserNameByUID(item.uid)}: {item.text}
        </SubtitleContents>
      ))}
    </StyledSubtitleContainer>
  );
});
