import { RtmRole, RtmTokenBuilder } from "agora-access-token";
import AgoraRTM from "agora-rtm-sdk";
import { makeAutoObservable, runInAction } from "mobx";
import { config } from "~/utils/agora.config";
import { appStore } from "./app.store";

const { RTM } = AgoraRTM;

/**
 * RTM 메시지 인터페이스 정의
 */
interface RTMMessage {
  type: string;
  action: string;
  data: any;
}

/**
 * RTM 상태 관리를 위한 클래스
 */
class RtmStore {
  rtmClient: any | null = null; // RTM 클라이언트
  rtmMessage: RTMMessage | null = null; // RTM 메시지

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * RTM 토큰을 생성하는 함수
   * @param uid - 사용자 ID
   * @returns 생성된 RTM 토큰
   */
  generateToken = async (uid: string): Promise<string> => {
    return RtmTokenBuilder.buildToken(
      config.appId,
      config.appCertificate,
      uid,
      RtmRole.Rtm_User,
      Math.floor(Date.now() / 1000) + 3600
    );
  };

  /**
   * 주어진 방 이름으로 RTM 클라이언트에 참여하는 함수
   * @param roomName - 방 이름
   * @param uid - 사용자 ID
   */
  join = async (roomName: string, uid: string): Promise<void> => {
    let rtmToken = "";
    if (uid === "123") {
      rtmToken = await this.generateToken(uid);
    } else {
      rtmToken = appStore.rtmToken;
    }
    const rtmClient = new RTM(config.appId, appStore.rtmUid, {
      token: rtmToken,
    });
    await rtmClient.login();
    console.log("RTM client uid:", rtmClient.userId);
    console.log("RTM login successful");
    await rtmClient.subscribe(roomName);
    console.log("RTM subscribe successful");
    runInAction(() => {
      this._updateClient(rtmClient);
    });
  };

  /**
   * RTM 클라이언트에서 나가는 함수
   */
  leave = async (): Promise<void> => {
    const rtmClient = this.rtmClient;
    if (rtmClient) {
      await rtmClient.unsubscribe(appStore.roomName);
      console.log("RTM unsubscribe successful");
      await rtmClient.logout();
      console.log("RTM logout successful");
      runInAction(() => {
        this._updateClient(null);
      });
    }
  };

  /**
   * RTM 메시지를 보내는 함수
   * @param message - 보낼 RTM 메시지
   */
  sendMessage = async (message: RTMMessage): Promise<void> => {
    if (!this.rtmClient) {
      console.error("RTM client is not initialized.");
      return;
    }
    try {
      const messageString = JSON.stringify(message);
      await this.rtmClient.publish(appStore.roomName, messageString);
      // console.log("Message sent successfully", messageString);
    } catch (status) {
      console.error("Failed to send message", status);
    }
  };

  /**
   * RTM 클라이언트를 업데이트하는 함수
   * @param rtmClient - 업데이트할 RTM 클라이언트
   */
  private _updateClient = (rtmClient: any | null): void => {
    this.rtmClient = rtmClient;
    if (this.rtmClient) {
      // 메시지 이벤트 리스너 추가
      this.rtmClient.addEventListener("message", (event: any) => {
        try {
          const parsedMessage: RTMMessage = JSON.parse(event.message);
          runInAction(() => {
            this.rtmMessage = parsedMessage;
          });
          // console.log(`${event.publisher}: ${event.message}`);
        } catch (error) {
          console.error("Invalid message format", error);
        }
      });
      // 저장소 이벤트 리스너 추가
      this.rtmClient.addEventListener("storage", (event: any) => {
        // console.log(`${event.publisher}: ${event.message}`);
      });
      // 프레젠스 이벤트 리스너 추가
      this.rtmClient.addEventListener("presence", (event: any) => {
        const action = event.eventType;
        // const channelName = event.channelName;
        // const publisher = event.publisher;
        if (action === "LEAVE") {
          // console.log(`${publisher} has left the channel ${channelName}`);
        }
      });
    }
  };
}

export type { RTMMessage };

export const rtmStore = new RtmStore();
