import { styled } from "styled-components";
import { observer } from "mobx-react-lite";
import { appStore } from "~/stores/app.store";
import { ReactComponent as MicOffIcon } from "~/assets/images/mic_off.svg";
import { ReactComponent as CameraOffIcon } from "~/assets/images/videocam_off.svg";
import { MicIconAnimation } from "~/components/atoms/mic-icon-animation/MicIconAnimation";

const DeviceStateContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  gap: 12px;
`;

const StateItem = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    color: #8c8c8c;
  }
`;

const MicControlContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeviceState = observer(() => {
  const { localUser } = appStore.users;

  return (
    <DeviceStateContainer>
      {!localUser?.cameraOn && (
        <StateItem>
          <CameraOffIcon />
        </StateItem>
      )}
      {localUser?.micOn ? (
        <MicControlContainer>
          <MicIconAnimation
            className="modal"
            audioTrack={localUser.micTrack}
            micOn
          />
        </MicControlContainer>
      ) : (
        <StateItem>
          <MicOffIcon />
        </StateItem>
      )}
    </DeviceStateContainer>
  );
});
