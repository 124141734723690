Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthHeader = void 0;
// Base64 인코딩 함수
var encodeBase64 = function (input) {
  return btoa(input);
};
var customerId = process.env.REACT_APP_AGORA_RTC_CUSTOMER_ID;
var customerSecret = process.env.REACT_APP_AGORA_RTC_CUSTOMER_SECRET;
function AuthHeader() {
  var authHeader = "Basic ".concat(
    encodeBase64("".concat(customerId, ":").concat(customerSecret))
  );
  return authHeader;
}
exports.AuthHeader = AuthHeader;
