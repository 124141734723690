import { ControlsFeatureContainer } from "./ControlsFeatureStyles";
import { MicButton } from "~/components/atoms/feature-buttons/mic-button/MicButton";
import { CameraButton } from "~/components/atoms/feature-buttons/camera-button/CameraButton";
import { RttButton } from "~/components/atoms/feature-buttons/rtt-button/RttButton";
import { ScreenShareButton } from "~/components/atoms/feature-buttons/screen-share-button/ScreenShareButton";
import { WhiteBoardButton } from "~/components/atoms/feature-buttons/whiteboard-button/WhiteBoardButton";
import { RecordButton } from "~/components/atoms/feature-buttons/record-button/RecordButton";
import { MemoButton } from "~/components/atoms/feature-buttons/memo-button/MemoButton";

/**
 * 기능 버튼들을 포함하는 컨트롤 컴포넌트
 */
export const ControlsFeature = () => {
  return (
    <ControlsFeatureContainer>
      {/* 마이크 버튼 컴포넌트 */}
      <MicButton />
      {/* 카메라 버튼 컴포넌트 */}
      <CameraButton />
      {/* 녹화 버튼 컴포넌트 */}
      {/* <RecordButton /> */}
      {/* 자막 버튼 컴포넌트 */}
      <RttButton />
      {/* 화면공유 버튼 컴포넌트 */}
      <ScreenShareButton />
      {/* 화이트보드 버튼 컴포넌트 */}
      <WhiteBoardButton />
      {/* 메모 버튼 컴포넌트 */}
      <MemoButton />
    </ControlsFeatureContainer>
  );
};
