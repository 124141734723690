import styled from "styled-components";
import { useState } from "react";
import { TextAreaScroll } from "~/components/molecules/memo-elements/MemoScroll";
import { postKnuhData } from "~/apis/knuh/postKnuhData";

const MemoArea = styled.div`
  width: 328px;
  height: 172px;
  padding: 16px;
  border-radius: 8px;
  //gap: 12px;
  border: 1px solid #59595c;
  background-color: #353536;
  display: flex;
  flex-direction: column;
  //box-sizing: border-box;
`;

const TextBox = styled(TextAreaScroll)`
  width: 100%;
  height: 92px;
  margin-bottom: 12px;
  border: none;
  background-color: transparent;
  color: white;
  font-weight: 400;
  font-size: 16px;
  outline: none; /* 포커스시 테두리 제거 */
  resize: none; /* 크기 조정 금지 */
  overflow-y: auto;
  font-family: "Pretendard";
`;

export const Button = styled.button`
  all: unset; /* 기본 스타일 제거 */
  width: 54px;
  height: 34px;
  border-radius: 8px;
  background-color: ${({ disabled }) => (disabled ? "#CCCCCC" : "#008DEF")};
  text-align: center;
  border: 1px #27a3fc; /* 외곽선 설정 */
  font-size: 16px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center; /* 가로축 가운데 정렬 */
  align-items: center; /* 세로축 가운데 정렬 */
  margin-left: 240px; //문제있을시 컨테이너에 담고 justify-content: flex-end; 선언
`;

interface MemoAreaProps {
  isFocused?: boolean;
}

export const MemoPostBox: React.FC<MemoAreaProps> = ({ isFocused }) => {
  const [content, setContent] = useState(""); // 텍스트 상자의 내용을 상태로 관리
  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value); // 텍스트 상자의 내용 업데이트
  };

  const reset = () => {
    setContent("");
  };

  const handlePostClick = () => {
    postKnuhData(content);
    reset();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // 엔터 키 기본 동작 취소
      if (content) {
        handlePostClick();
        // reset();
      }
    }
  };

  return (
    <MemoArea>
      <TextBox
        placeholder="메모를 입력해주세요."
        onChange={handleTextChange}
        value={content}
        onKeyPress={handleKeyPress}
      />

      <Button content={content} disabled={!content} onClick={handlePostClick}>
        입력
      </Button>
    </MemoArea>
  );
};
